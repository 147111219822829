import PropTypes from "prop-types";
import { assetModel } from "./assetFactory";
import {
    ButtonIconSave, ButtonIconCancel, useInputForm, clearInputFormErrorFields, TextField, inputFormIsValid
} from "../../Components/Inputs";
import { isTrimmedStringEmpty } from "../../Utilities/commonUtilities";
import { focusOnFormElement } from "../../Components/Inputs/inputUtility";
import { useEffect } from "react";

const EditMediaAsset = ({
    mediaAsset,
    setWarnings,
    cancelEditing,
    handleSaveNewFolder,
    handleRenameAsset
}) => {

    const assetForm = useInputForm(assetModel(mediaAsset));

    useEffect(() => {
        focusOnFormElement("name");
    }, []);

    const handleCancelClick = (event) => {
        event.preventDefault();
        cancelEditing()
    };

    const handleSaveClick = (event) => {
        event.preventDefault();
        if (isFormValid()) {
            isTrimmedStringEmpty(mediaAsset.name)
                ? handleSaveNewFolder(assetForm.name.value)
                : handleRenameAsset(mediaAsset.name, assetForm.name.value)
        }
    };

    const isFormValid = () => {
        clearInputFormErrorFields(assetForm);

        if (isTrimmedStringEmpty(assetForm.name.value)) {
            assetForm.name.setError("Name is required.");
        }

        let isValid = inputFormIsValid(assetForm);

        if (!isValid)
            setWarnings(["Missing required items.  Please review your responses and try submitting again."]);

        return isValid;
    };

    return <tr>
        <td><TextField {...assetForm.name} showOnlyForSr /></td>
        {
            (isTrimmedStringEmpty(mediaAsset.name)) &&
            <td>Updated on save</td>
        }
        {
            (!isTrimmedStringEmpty(mediaAsset.name)) &&
            <td>{mediaAsset.dateModifiedOutput}</td>
        }
        <td className={"no-wrap align-center"}>
            <ButtonIconSave
                inputDescription={`for ${mediaAsset.name}`}
                onClick={handleSaveClick}
            />
            <ButtonIconCancel
                inputDescription={`for ${mediaAsset.name}`}
                onClick={handleCancelClick}
            />
        </td>
    </tr>;
}

EditMediaAsset.propTypes = {
    mediaAsset: PropTypes.object,
    setWarnings: PropTypes.func.isRequired,
    cancelEditing: PropTypes.func.isRequired,
    handleSaveNewFolder: PropTypes.func.isRequired,
    handleRenameAsset: PropTypes.func.isRequired

};

export default EditMediaAsset;