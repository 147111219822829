import PropTypes from "prop-types";
import React from "react";
import ActivityWebGlView from "../../Components/Activity/ActivityWebGlView";
import {Layout} from "../../Components/Layout";
import {Link} from "../../Components/Link";
import button_back from "../../images/button_back.png";
import {useDocumentTitle} from "../../Utilities/landmarkUtilities";
import {locations} from "../../Utilities/Location";
import {getActivityType} from "../activityUtilities";

const ActivityContainer = ({
                               activity,
                               activityId,
                               activityDetails,
                               redirect
                           }) => {
    const activityType = getActivityType(activity);

    if (!activityType) {
        redirect(locations.LANDING.path);
    }

    const activityDetail = activityDetails.find(f => f.id === activityId);

    useDocumentTitle(activityDetail?.title);

    if (activityDetails.length === 0 || !activityDetail) {
        return null;
    }

    return (
        <Layout>
            <div className="by_grade_level">
                <div className="by_grade_level__header">
                    <div className="by_grade_level__back">
                        <Link to={locations.ACTIVITY_SEARCH_RESULTS.getPath(activity)} label={<img src={button_back} alt="Back"/>} />
                    </div>
                    <h2>{activityDetail.title}</h2>
                </div>
                <ActivityWebGlView activityDetail={activityDetail} />
            </div>
        </Layout>
    );
};

ActivityContainer.propTypes = {
    activity: PropTypes.string.isRequired,
    activityId: PropTypes.string.isRequired,
    activityDetails: PropTypes.array.isRequired,
    redirect: PropTypes.func.isRequired
}

export default ActivityContainer;
