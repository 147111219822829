import PropTypes from "prop-types";
import React from "react";
import {PageNavigation, PageTitle} from "../../Components/Landmarks";
import {ContentSmall} from "../../Components/Layout";
import {locations} from "../../Utilities/Location";

export const NotFoundFactory = ({
                                    header = "Page Not Found",
                                    message = "The location you arrived at is not valid. Please try again.",
                                    redirect,
                                }) => {

    const handlePrimaryClick = () => {
        redirect(locations.LANDING.path);
    };

    return (
        <ContentSmall>
            <PageTitle h1={header} isCentered/>

            <div className={`fourohfour`}>
                {
                    message &&
                    <p className="text-center">{message}</p>
                }
            </div>
            <PageNavigation
                handlePrimaryClick={handlePrimaryClick}
                labelPrimary={`Return Home`}
            />
        </ContentSmall>
    );
};

export const NotFoundContainer = (props) => <NotFoundFactory {...props} />;

NotFoundFactory.propTypes = {
    header: PropTypes.string,
    message: PropTypes.string,
    redirect: PropTypes.func.isRequired,
};

