import {
    config,
    requestOptions,
    requestTypes,
    sendRequest,
} from "../Utilities/Api";

//************************************************** STANDARD API SERVER CALLS ****************************************

export default class activityApi { 
    static async getActivityManifests(publishedOnly) {
        return sendRequest(requestOptions(`${config.URL}manifest`, 
        requestTypes.GET,
        {publishedOnly: publishedOnly}
        ));
    }

    static async getActivityDetails(url) {
        return sendRequest(requestOptions(url, requestTypes.GET));
    }
}