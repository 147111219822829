import { isNullOrUndefined } from "../commonUtilities";
import { deepCopyObject, objectHasProperty } from "./objectUtilities";
import { isArray, isString } from "./typeUtilities";

export function isInArray(array, value) {
    return array.includes(value);
}

export function isArrayNullOrEmpty(array) {
    return isNullOrUndefined(array) || !(isArray(array) && array.length !== 0);
}

export function addUniqueValueToArray(array, value) {
    array = !array ? [] : convertToArray(array);

    if (!isInArray(array, value))
        array.push(value);

    return array;
}

export function convertToArray(unknownType, delimiter = ",") {
    return isNullOrUndefined(unknownType) ?
        [] :
        isArray(unknownType) ?
            unknownType :
            isString(unknownType) ?
                unknownType.split(delimiter) :
                [unknownType];
}

export function deepCopyArray(array) {
    if (isArrayNullOrEmpty(array)) return [];
    return deepCopyObject(array);
}

export function arraysEqual(a, b) {
    /*
        https://stackoverflow.com/questions/3115982/how-to-check-if-two-arrays-are-equal-with-javascript
        Array-aware equality checker:
        Returns whether arguments a and b are == to each other;
        however if they are equal-lengthed arrays, returns whether their
        elements are pairwise == to each other recursively under this
        definition.
    */
    if (a instanceof Array && b instanceof Array) {
        if (a.length !== b.length)  // assert same length
            return false;
        for (let i = 0; i < a.length; i++)  // assert each element equal
            if (!arraysEqual(a[i], b[i]))
                return false;
        return true;
    }
    else {
        return a === b;  // if not both arrays, should be the same
    }
}

export function arraysOfObjectsEqual(array1, array2) {
    return JSON.stringify(array1) === JSON.stringify(array2);
}

export function filterObjectArrayByField(array, filter, field) {
    if (!filter || isArrayNullOrEmpty(array)) return [];

    let result;
    const searchStrings = filter.toString().split(",").map((d) => d.trim());
    result = array.filter((ins) =>
        searchStrings.some(function(search) {
            return ins[field].toString() === search;
        }),
    );

    return result;
}

export function findObjectArrayByField(array, filter, field) {
    return filterObjectArrayByField(array, filter, field)[0];
}

export function createArrayFromObject(object) {
    let result = [];
    for (let property in object) {
        if (objectHasProperty(object, property)) {
            result.push(object[property]);
        }
    }
    return result;
}

export function optionIndexInArray(array, option = "", wholeWord = true) {
    const optionValue = option.toLowerCase();

    for (let i = 0; i < array.length; i++) {
        const arrayValue = array[i].toLowerCase();
        if ((wholeWord && arrayValue === optionValue) || (!wholeWord && arrayValue.startsWith(optionValue)))
            return i;
    }

    return -1;
}

export function partialOptionIndexInArray(array, option = "") {
    const optionValue = option.toLowerCase();

    for (let i = 0; i < array.length; i++) {
        const arrayValue = array[i].toLowerCase();
        if (optionValue.startsWith(arrayValue)) return i;
    }

    return -1;
}

export function getDistinctValuesByObjectProperty(array, property) {
    return [
        ...new Set(
            array.map((item) => {
                return item[property];
            }),
        ),
    ];
}