import PropTypes from "prop-types";
import { ButtonIconDelete, ButtonIconLock, ButtonIconRename } from "../../Components/Inputs";

const MediaAssetFolderView = (
    {
        mediaAsset,
        navigateToAsset,
        isEditing,
        setEditAssetName,
        handleDeleteAsset,
        handleOpenModal
    }) => {

    const handleFolderClick = (event) => {
        event.preventDefault();
        if (!isEditing)
            navigateToAsset(mediaAsset.name);
    };

    const handleDeleteFolderClick = (event) => {
        event.preventDefault();
        handleDeleteAsset(mediaAsset.name);
    };

    const handleRenameFolderClick = (event) => {
        event.preventDefault();
        setEditAssetName(mediaAsset.name);
    };

    const handleLockIconButtonClick = (event) => {
        event.preventDefault();
        handleOpenModal(mediaAsset);
    };

    return (
        <tr>
            <td onClick={handleFolderClick}
                className={isEditing ? `` : `is-clickable`}>
                <div className={'asset asset--folder'}>
                    {mediaAsset.name}
                </div>
            </td>
            <td>{mediaAsset.dateModifiedOutput}</td>
            {
                !mediaAsset.isUsed &&
                <td className={"no-wrap align-center"}>
                    <ButtonIconRename
                        disabled={isEditing}
                        inputDescription={`for ${mediaAsset.name}`}
                        onClick={handleRenameFolderClick}
                    />
                    <ButtonIconDelete
                        disabled={isEditing}
                        inputDescription={`for ${mediaAsset.name}`}
                        onClick={handleDeleteFolderClick}
                    />
                </td>
            }
            {
                mediaAsset.isUsed &&
                <td className={"no-wrap align-center"}>
                    <ButtonIconLock
                        disabled={isEditing}
                        inputDescription={`for ${mediaAsset.name}`}
                        onClick={(event) => handleLockIconButtonClick(event)}
                    />

                </td>
            }

        </tr>
    );
};

MediaAssetFolderView.propTypes = {
    mediaAsset: PropTypes.object,
    navigateToAsset: PropTypes.func.isRequired,
    isEditing: PropTypes.bool.isRequired,
    setEditAssetName: PropTypes.func.isRequired,
    handleDeleteAsset: PropTypes.func.isRequired,
    handleOpenModal: PropTypes.func.isRequired
};

export default MediaAssetFolderView;
