import PropTypes from "prop-types";
import React from "react";
import {HtmlDisplay} from "../Display";
import {GridCell} from "../Grid";
import {Notification} from "./Notification";

const DisplayLayout = ({children, error, label, medium_columns, name}) => {
    return (
        <GridCell medium_columns={medium_columns}>
            <HtmlDisplay className={`label`} html={label} name={name}/>
            <div aria-labelledby={name}>
                {children}
            </div>
            <Notification error={error} name={name} label={label}/>
        </GridCell>
    );
};

DisplayLayout.propTypes = {
    children: PropTypes.any.isRequired,
    error: PropTypes.string,
    label: PropTypes.string.isRequired,
    medium_columns: PropTypes.string,
    name: PropTypes.string.isRequired,
};

export {DisplayLayout};
