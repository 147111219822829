import PropTypes from "prop-types";
import React from "react";
import { useParams } from "react-router-dom";
import { isNullOrUndefined } from "../../Utilities/commonUtilities";
import NestAdInfinitum from "../AdInfinitum/NestAdInfinitum";

export const ContextPropsProvider = ({
    children,
    providers
}) => {
    return (
        <NestAdInfinitum array={providers}>
            {children}
        </NestAdInfinitum>
    );
};

ContextPropsProvider.propTypes = {
    children: PropTypes.any,
    providers: PropTypes.array,
};

export const ContextPropsConsumer = ({
    children,
    contexts,
    props,
}) => {
    const params = useParams();

    // MediaAssets needs to navigate through directories like /photos/2023/ and we need to pass that in the url as a parameter.
    // We can achieve this behavior through splats. But we have to manually add the property back to the component from splat.
    // Reference : https://reactrouter.com/en/main/route/route#splats
    const mediaAssetsPath = params['*'];
    if (!isNullOrUndefined(mediaAssetsPath))
        params.mediaAssetsPath = mediaAssetsPath;
    let contextProps = {};

    contexts.map(context => Object.assign(contextProps, context()));

    return (
        children.map((Component, key) => {
            if (!Component) return null;
            return <Component
                key={key}
                {...props}
                {...contextProps}
                {...params}
            />
        },
        )
    );
};

ContextPropsConsumer.propTypes = {
    children: PropTypes.array,
    contexts: PropTypes.array,
    props: PropTypes.object,
};