import PropTypes from "prop-types";
import React from "react";
import { GridCell, gridConstants } from "../../Components/Grid";
import {
    Button,
    clearInputFormErrorFields,
    EmailField,
    InputForm,
    inputFormIsValid,
    useInputForm
} from "../../Components/Inputs";
import { PageTitle } from "../../Components/Landmarks";
import { ContentSmall } from "../../Components/Layout";
import Link from "../../Components/Link";
import { isTrimmedStringEmpty } from "../../Utilities/commonUtilities";
import { locations } from "../../Utilities/Location";

const ForgotPasswordView = ({
    isLoading,
    handleEmailAsync, setWarnings }) => {
    const form = useInputForm({ email: "" });

    const isFormValid = () => {
        clearInputFormErrorFields(form);
        if (isTrimmedStringEmpty(form.email.value)) {
            form.email.setError("Email is required.");
        }

        let isValid = inputFormIsValid(form);

        if (!isValid)
            setWarnings(["Missing required items.  Please review your responses and try submitting again."]);

        return isValid;
    };

    const handleClickSubmit = async () => {
        if (isFormValid()) {
            handleEmailAsync(form.email.value);
        }
    };

    return (
        <ContentSmall>
            <PageTitle h1={`Forgot Password`} hasBorder />

            <InputForm name={"forgotPw"}>
                <GridCell>
                    <p>Submit the email address associated with your account below.
                        You will receive an email with a link to update your password.</p>
                </GridCell>

                <EmailField {...form.email} />

                <GridCell>
                    <Button
                        isPrimary
                        name="btnSubmit"
                        label="Request Password Reset Email"
                        onClick={handleClickSubmit}
                        disabled={isLoading}
                    />
                </GridCell>

                <GridCell small_columns={gridConstants.column.SIX}>
                    <p>
                        <Link {...locations.SIGN_IN.link} />
                    </p>
                </GridCell>

                <GridCell small_columns={gridConstants.column.SIX}>
                    {
                        <p className={`align-right`}>
                            <Link {...locations.REGISTER.link} />
                        </p>
                    }
                </GridCell>
            </InputForm>

        </ContentSmall>
    );
};

ForgotPasswordView.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    handleEmailAsync: PropTypes.func.isRequired,
    setWarnings: PropTypes.func.isRequired,
};

export default ForgotPasswordView;
