import PropTypes from "prop-types";
import {useEffect, useState} from "react";
import {PageTitle} from "../../../Components/Landmarks";
import {isNullOrUndefined} from "../../../Utilities/commonUtilities";
import {locations} from "../../../Utilities/Location";
import {setWarnings} from "../../../Utilities/testingUtilities";
import {createListItem} from "../../../Utilities/Types/listUtilities";
import activitySearchApi from "../ActivitySearch/activitySearchApi";
import {activitySearchModel} from "../ActivitySearch/activitySearchFactory";
import activitySettingsApi from "./activitySettingsApi";
import {REDIRECT_LOCATIONS} from "./activitySettingsConstants";
import {createActivitySettings} from "./activitySettingsFactory";
import ActivitySettingsFormView from "./ActivitySettingsFormView";

const ActivitySettingsContainer = ({
                                       activityId,
                                       handleApiCall,
                                       isLoading,
                                       redirect
                                   }) => {
    const [activitySettings, setActivitySettings] = useState(null);
    const [allActivities, setAllActivities] = useState(null);

    const saveActivitySettings = (activitySettings, redirectLocation) => {
        const saveApi = isNullOrUndefined(activityId) ? activitySettingsApi.addActivity : activitySettingsApi.updateActivity;
        const redirectTo = redirectLocation === REDIRECT_LOCATIONS.Activity ? redirectToEditActivity
            : redirectLocation === REDIRECT_LOCATIONS.Assets ? redirectToMediaAssets : redirectToActivitySearch;
        handleApiCall(() => saveApi(activitySettings), redirectTo);
    };

    const redirectToActivitySearch = () => {
        redirect(locations.MANAGE_ACTIVITIES.path);
    };

    const redirectToEditActivity = (activity) => {
        redirect(locations.EDIT_ACTIVITY.getPath(activity.activityId));
    };

    const redirectToMediaAssets = (activity) => {
        redirect(locations.MEDIA_ASSETS_REDIRECT.getPath(activity.activityManifestId));
    };

    const loadActivitySettingsResults = (results) => {
        setActivitySettings(createActivitySettings(results));
    }

    const loadActivitySettings = () => {
        handleApiCall(() => activitySettingsApi.getActivitySettings(activityId), loadActivitySettingsResults);
    };

    const loadAllActivities = () => {
        handleApiCall(() => activitySearchApi.getActivities(activitySearchModel()), (results) => {
            const activitiesToDuplicate = results.data.map((activity) => createListItem(activity.activityId, `${activity.activityTitle} (${activity.activityManifestId})`));
            setAllActivities(activitiesToDuplicate);
        });
    }

    useEffect(() => {
        if (isNullOrUndefined(activityId)) {
            loadActivitySettingsResults();
            loadAllActivities();
        }
        else
            loadActivitySettings();

    }, []);  // eslint-disable-line react-hooks/exhaustive-deps

    if (!activitySettings) return null;

    return (
        <>
            <PageTitle h1={locations.EDIT_ACTIVITY_SETTINGS.label} isCentered/>

            <ActivitySettingsFormView
                activitySettings={activitySettings}
                allActivities={allActivities}
                isLoading={isLoading}
                redirectToActivitySearch={redirectToActivitySearch}
                saveActivitySettings={saveActivitySettings}
                setWarnings={setWarnings}
            />
        </>
    );
};

ActivitySettingsContainer.propTypes = {
    activityId: PropTypes.string,
    handleSetActivitySearchCriteria: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    redirect: PropTypes.func.isRequired,
};

export default ActivitySettingsContainer;