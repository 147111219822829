export const apiConstants = {
    apiRedirectPath: "/",
    contentType: {
        JSON: `application/json`,
        MULTI: `multipart/form-data`
    },
    successMessage: {
        GENERIC: `Success`
    },
    user: {
        FORGOT_PASSWORD: `Email sent. Check your inbox for further instructions.`,
        RESET_PASSWORD: `Your password has been successfully reset.`,
        UPDATE_USER: `You have successfully updated the user's details.`,
        ADMIN_FORGOT_PASSWORD: `Email sent. Inform the user to check their inbox.`,
    },
    asset: {
        ADD_NEW_FOLDER: `You have successfully created a new folder.`,
        DELETE_ASSET: `You have successfully deleted the asset.`,
        RENAME_ASSET: `You have successfully renamed the asset.`,
        UPLOAD_ASSETS: `You have successfully uploaded assets.`,
    },
    activitySettings: {
        UPDATE_ACTIVITY_SETTINGS: `You have successfully updated the activity settings.`,
        ADD_ACTIVITY_SETTINGS: `You have successfully added the activity settings.`
    },
    activityDetails: {
        UPDATE_ACTIVITY_DETAILS: `You have successfully updated the activity.`,
    }
};
export default apiConstants;
