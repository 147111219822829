import store from "store";
import {convertToBoolean, convertToNumber} from "../commonUtilities";
import { isNumber } from "../Types/typeUtilities";
import * as storageTypes from "./storageTypes";

export function isLoggedIn() {
	const userIdentity = store.get(storageTypes.CURRENT_USER);
	return isNumber(userIdentity);
}

export function isAdmin() {
	return convertToBoolean(store.get(storageTypes.IS_ADMIN));
}

export function getRoleId() {
	return convertToNumber(store.get(storageTypes.ROLE_ID));
}

export function persistLogin(userIdentity, accessToken, refreshToken, isAdmin, roleId) {
	store.set(storageTypes.CURRENT_USER, userIdentity);
	store.set(storageTypes.ACCESS_TOKEN, accessToken);
	store.set(storageTypes.REFRESH_TOKEN, refreshToken);
	store.set(storageTypes.IS_ADMIN, isAdmin);
	store.set(storageTypes.ROLE_ID, roleId);
}

export function desistLogin() {
	store.remove(storageTypes.CURRENT_USER);
	store.remove(storageTypes.ACCESS_TOKEN);
	store.remove(storageTypes.REFRESH_TOKEN);
	store.remove(storageTypes.IS_ADMIN);
	store.remove(storageTypes.ROLE_ID);
}

export function getLoggedInUser() {
	return store.get(storageTypes.CURRENT_USER);
}

export function getAccessToken() {
	return "Bearer " + store.get(storageTypes.ACCESS_TOKEN);
}

export function saveJwtToken(token) {
	store.set(storageTypes.ACCESS_TOKEN, token);
}

export function saveRefreshToken(refreshToken) {
	store.set(storageTypes.REFRESH_TOKEN, refreshToken);
}

export function getJwtToken() {
	return store.get(storageTypes.ACCESS_TOKEN);
}

export function getRefreshToken() {
	return store.get(storageTypes.REFRESH_TOKEN);
}
const storageUtilitiesProps = {isLoggedIn, persistLogin, desistLogin, getLoggedInUser};
export default storageUtilitiesProps;