import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { PageTitle } from "../../Components/Landmarks";
import { isNullOrUndefined } from "../../Utilities/commonUtilities";
import { locations } from "../../Utilities/Location";
import userMgmtApi from "./userMgmtApi";
import UserSearchFormView from "./UserSearchFormView";
import UserSearchResultsView from "./UserSearchResultsView";

const UserSearchContainer = ({
    handleApiCall,
    handleSetUserSearchCriteria,
    isLoading,
    redirect,
    setWarnings,
    userSearchCriteria
}) => {
    const [users, setUsers] = useState(null);
    const [totalRecords, setTotalRecords] = useState(0);
    const [editId, setEditId] = useState(null);

    const handleSaveUser = (userDetails, callback) => {
        handleApiCall(() => userMgmtApi.updateUser(userDetails), () => {
            if (callback)
                callback();
            setEditId(null);
            loadUsers();
        });
    }

    const handleResetPassword = async (email) => {
        return handleApiCall(() => userMgmtApi.forgotPassword(email),
        );
    };

    const loadUsersResults = (results) => {
        setUsers(results.data);
        setTotalRecords(results.totalRecords);
    };

    const loadUsers = () => {
        handleApiCall(() => userMgmtApi.getUsers(userSearchCriteria), loadUsersResults);
    };

    useEffect(() => {
        if (!userSearchCriteria) return;

        if (userSearchCriteria.loadSearchResults)
            loadUsers();

    }, [userSearchCriteria]); // eslint-disable-line react-hooks/exhaustive-deps

    if (!userSearchCriteria) return null;

    const isEditing = !isNullOrUndefined(editId);

    return (
        <>
            <PageTitle h1={locations.MANAGE_USERS.label} isCentered />

            <UserSearchFormView
                handleSetUserSearchCriteria={handleSetUserSearchCriteria}
                isEditing={isEditing}
                isLoading={isLoading}
                redirect={redirect}
                userSearchCriteria={userSearchCriteria}
            />

            <UserSearchResultsView
                editId={editId}
                totalRecords={totalRecords}
                handleResetPassword = {handleResetPassword}
                handleSaveUser={handleSaveUser}
                handleSetUserSearchCriteria={handleSetUserSearchCriteria}
                isEditing={isEditing}
                isLoading={isLoading}
                setEditId={setEditId}
                setWarnings={setWarnings}
                users={users}
                userSearchCriteria={userSearchCriteria}
            />
        </>
    );
};

UserSearchContainer.propTypes = {
    handleApiCall: PropTypes.func.isRequired,
    handleSetUserSearchCriteria: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    redirect: PropTypes.func.isRequired,
    setWarnings: PropTypes.func.isRequired,
    userSearchCriteria: PropTypes.object
};

export default UserSearchContainer;