import {
    apiConstants,
    config,
    mockResolveApiCall,
    requestOptions,
    requestTypes,
    sendRequest,
} from "../../../Utilities/Api";

//************************************************** STANDARD API SERVER CALLS ****************************************

class ServerActivityDetailsApi {
    static getActivityDetails(activityId) {
        return sendRequest(
            requestOptions(`${config.URL}admin/activities/${activityId}/json`,
                requestTypes.GET
            ),
        );
    }

    static updateActivityDetails(activityId, activityDetails) {
        return sendRequest(
            requestOptions(
                `${config.URL}admin/activities/${activityId}/json`,
                requestTypes.POST,
                JSON.stringify(activityDetails),
                apiConstants.activityDetails.UPDATE_ACTIVITY_DETAILS
            )
        );
    }
}

//************************************************** MOCK API SERVER CALLS ****************************************

class MockActivityDetailsApi {
    static getActivityDetails() {
        return mockResolveApiCall();
    }

    static updateActivityDetails() {
        return mockResolveApiCall();
    }
}

const ActivityDetailsApi = config.environment.MODE === config.modes.SERVER ? ServerActivityDetailsApi : MockActivityDetailsApi;
export default ActivityDetailsApi;