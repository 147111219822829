import React from "react";
import { Routes, Route } from "react-router-dom";
import {TemplateFullAdmin} from "../../Components/Layout/TemplateFullAdmin";
import { PageSetup } from "../../Containers/PageSetup";
import { Landing } from "../../Containers/Landing";
import { ActivitySearchResults } from "../../Containers/ActivitySearchResults";
import { Activity } from "../../Containers/Activity";
import { locations } from "../../Utilities/Location";
import { TemplateAdmin } from "../../Components/Layout";
import { TemplateBasic as Template } from "../../Components/Layout";
import { Register, SuccessfulRegister } from "../../Containers/Register";
import { SignIn } from "../../Containers/SignIn";
import SignOut from "../../Containers/SignOut";
import { Dashboard } from "../../Containers/Dashboard";
import { UserMgmt } from "../../Containers/UserMgmt";
import { ActivitySearch } from "../../Containers/Activity/ActivitySearch";
import {ActivitySettings} from "../../Containers/Activity/ActivitySettings";
import {ActivityDetails} from "../../Containers/Activity/ActivityDetails";
import {ActivityPreview} from "../../Containers/Activity/ActivityPreview";
import { NotAuthorized } from "../../Components/Authorize"
import { NotFound } from "../../Containers/NotFound"
import { ResetPassword, ForgotPassword } from "../../Containers/Password";
import { MediaAssets } from "../../Containers/MediaAssets";

const SiteRoutes = () => {
    return (
        <Routes>
            <Route path={locations.LANDING.path} element={<PageSetup component={Landing} path={locations.LANDING.path} template={Template} />} />
            <Route path={locations.ACTIVITY_SEARCH_RESULTS.path} element={<PageSetup component={ActivitySearchResults} path={locations.ACTIVITY_SEARCH_RESULTS.path} template={Template} />} />
            <Route path={locations.PREVIEW_ACTIVITY.path} element={<PageSetup component={ActivityPreview} path={locations.PREVIEW_ACTIVITY.path} template={Template} />} />
            <Route path={locations.ACTIVITY.path} element={<PageSetup component={Activity} path={locations.ACTIVITY.path} template={Template} />} />
            <Route path={locations.REGISTER.path} element={<PageSetup component={Register} path={locations.REGISTER.path} template={TemplateAdmin} />} />
            <Route path={locations.SUCCESSFUL_REGISTER.path} element={<PageSetup component={SuccessfulRegister} path={locations.SUCCESSFUL_REGISTER.path} template={TemplateAdmin} />} />
            <Route path={locations.SIGN_IN.path} element={<PageSetup component={SignIn} path={locations.SIGN_IN.path} template={TemplateAdmin} />} />
            <Route path={locations.SIGN_OUT.path} element={<PageSetup component={SignOut} path={locations.SIGN_OUT.path} template={TemplateAdmin} />} />
            <Route path={locations.DASHBOARD.path} element={<PageSetup component={Dashboard} path={locations.DASHBOARD.path} template={TemplateAdmin} />} />
            <Route path={locations.MANAGE_USERS.path} element={<PageSetup component={UserMgmt} path={locations.MANAGE_USERS.path} template={TemplateAdmin} />} />
            <Route path={locations.MANAGE_ACTIVITIES.path} element={<PageSetup component={ActivitySearch} path={locations.MANAGE_ACTIVITIES.path} template={TemplateAdmin} />} />
            <Route path={locations.ADD_ACTIVITY_SETTINGS.path} element={<PageSetup component={ActivitySettings} path={locations.ADD_ACTIVITY_SETTINGS.path} template={TemplateAdmin} />} />
            <Route path={locations.EDIT_ACTIVITY_SETTINGS.path} element={<PageSetup component={ActivitySettings} path={locations.EDIT_ACTIVITY_SETTINGS.path} template={TemplateAdmin} />} />
            <Route path={locations.EDIT_ACTIVITY.path} element={<PageSetup component={ActivityDetails} path={locations.EDIT_ACTIVITY.path} template={TemplateFullAdmin} />} />
            <Route path={locations.FORGOT_PASSWORD.path} element={<PageSetup component={ForgotPassword} path={locations.FORGOT_PASSWORD.path} template={TemplateAdmin} />} />
            <Route path={locations.RESET_PASSWORD.path} element={<PageSetup component={ResetPassword} path={locations.RESET_PASSWORD.path} template={TemplateAdmin} />} />

            <Route path={locations.NOT_AUTHORIZED.path} element={<PageSetup component={NotAuthorized} path={locations.NOT_AUTHORIZED.path} template={TemplateAdmin} />} />
            <Route path={locations.MEDIA_ASSETS.path} element={<PageSetup component={MediaAssets} path={locations.MEDIA_ASSETS_REDIRECT.path} template={TemplateAdmin} />} />
            <Route path={`*`} element={<PageSetup component={NotFound} path={locations.NOT_FOUND.path} template={TemplateAdmin} />} />
        </Routes>
    );
};

export { SiteRoutes };