import PropTypes from "prop-types";
import { GridCell, GridContainer, GridTable, gridConstants } from "../../Components/Grid";
import MediaAssetFolderView from "./MediaAssetFolderView";
import MediaAssetFileView from "./MediaAssetFileView";
import { ASSET_TYPE } from "./mediaAssetTypeConstants";
import { Button } from "../../Components/Inputs";
import EditMediaAssetFolder from "./EditMediaAsset";
import { assetModel } from "./assetFactory";
import FileUploadButton from "../../Components/Inputs/FileUploadButton";
import { useState } from "react";
import LockedActivityModal from "./LockedActivityModal";

const MediaAssetsView = ({
    assets,
    navigateToAsset,
    setAssets,
    isEditing,
    editAssetName,
    setEditAssetName,
    handleSaveNewFolder,
    setWarnings,
    handleDeleteAsset,
    handleRenameAsset,
    handleUploadAssets,
    mediaAssetsPath
}) => {

    const [openModalForAsset, setOpenModalForAsset] = useState(null);
    const hoverClass = isEditing ? '' : 'row_hover';


    const handleAddNewFolderClick = (event) => {
        event.preventDefault();
        createTemporaryAssetForNewFolder();
    };

    const handleUploadFileChange = (event) => {
        event.preventDefault();
        uploadFiles(event.target.files);
    };

    const uploadFiles = (files, assetName) => {
        setEditAssetName("");
        if (files.length > 0) {
            let formData = new FormData();
            for (let i = 0; i < files.length; i++) {
                formData.append(assetName || files[i].name, files[i]);
            }
            handleUploadAssets(formData);

            let fileUploadElement = document.getElementById("fileUpload");
            fileUploadElement.value = "";
        }
    };

    const createTemporaryAssetForNewFolder = () => {
        setEditAssetName("");
        setAssets([assetModel({}), ...assets]);
    }

    const cancelEditing = () => {
        setEditAssetName(null);
        setAssets(assets.filter(asset => asset.name !== ""));
    }

    const handleCloseModal = () => { setOpenModalForAsset(null); }

    const handleOpenModal = (asset) => { setOpenModalForAsset(asset); }


    return (
        <>
            <LockedActivityModal
                assetName={openModalForAsset?.name}
                lockedActivities={openModalForAsset?.lockedActivities}
                handleCloseModal={handleCloseModal}
            />
            <GridContainer isReverseOrder className={`form__header`} hasMarginX>
                {
                    <GridCell large_columns={gridConstants.column.THREE} className={`h2 align-right`}>

                        <Button
                            disabled={isEditing}
                            label="Add New Folder"
                            name="btnAddNewFolder"
                            onClick={handleAddNewFolderClick}
                            isPrimary
                        />
                    </GridCell>

                }
                {
                    <GridCell large_columns={gridConstants.column.THREE} className={`h2 align-right`}>
                        <FileUploadButton
                            allowMultiple
                            disabled={isEditing}
                            label='Upload Files'
                            name='fileUpload'
                            onChange={handleUploadFileChange}
                        />
                    </GridCell>
                }
            </GridContainer>
            <GridTable className={`media_assets_table ${hoverClass}`}>
                <thead>
                    <tr>
                        <th scope={"col"}>
                            Name
                        </th>
                        <th scope={"col"}>
                            Date Modified
                        </th>
                        <th scope={"col"} className={"align-center"}>
                            Actions
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {assets?.map((asset) => {
                        const key = asset.name;
                        if (!isEditing || editAssetName !== asset.name) {
                            return asset.assetType === ASSET_TYPE.Folder
                                ? <MediaAssetFolderView
                                    key={key}
                                    mediaAsset={asset}
                                    navigateToAsset={navigateToAsset}
                                    isEditing={isEditing}
                                    setEditAssetName={setEditAssetName}
                                    handleDeleteAsset={handleDeleteAsset}
                                    handleOpenModal={handleOpenModal} />
                                : <MediaAssetFileView
                                    key={key}
                                    mediaAsset={asset}
                                    isEditing={isEditing}
                                    setEditAssetName={setEditAssetName}
                                    handleDeleteAsset={handleDeleteAsset}
                                    mediaAssetsPath={mediaAssetsPath}
                                    uploadFile={uploadFiles}
                                    handleOpenModal={handleOpenModal} />;
                        }
                        else {
                            return <EditMediaAssetFolder
                                key={key}
                                mediaAsset={asset}
                                setWarnings={setWarnings}
                                cancelEditing={cancelEditing}
                                handleSaveNewFolder={handleSaveNewFolder}
                                handleRenameAsset={handleRenameAsset} />;
                        }
                    })}
                </tbody>
            </GridTable>
        </>
    );

}

MediaAssetsView.propTypes = {
    assets: PropTypes.array,
    navigateToAsset: PropTypes.func.isRequired,
    setAssets: PropTypes.func.isRequired,
    isEditing: PropTypes.bool.isRequired,
    editAssetName: PropTypes.string,
    setEditAssetName: PropTypes.func.isRequired,
    handleSaveNewFolder: PropTypes.func.isRequired,
    setWarnings: PropTypes.func.isRequired,
    handleDeleteAsset: PropTypes.func.isRequired,
    handleRenameAsset: PropTypes.func.isRequired,
    handleUploadAssets: PropTypes.func.isRequired,
    mediaAssetsPath: PropTypes.string.isRequired
};

export default MediaAssetsView;



