import React from "react";
import PropTypes from "prop-types";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

export const Loading = ({
                     isLoading=false
                 }) => {
    return (
        <Backdrop
            open={isLoading}
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
    );
};

Loading.propTypes = {
    isLoading: PropTypes.bool
};