import PropTypes from "prop-types";
import React from "react";
import {ButtonIconResetPassword, ButtonIconEdit} from "../../Components/Inputs";

const UserSearchResultsRow = ({ handleResetPassword, disableEdit, setEditId, user}) => {
    const handleEditUserClick = (event, userId) => {
        event.preventDefault();
        setEditId(userId);
    };

    const handleResetPasswordClick = (event, email) => {
        event.preventDefault();
        handleResetPassword(email);
    };

    return (
        <tr>
            <td>{user.firstName}</td>
            <td>{user.lastName}</td>
            <td>{user.email}</td>
            <td>{user.userRole}</td>
            <td>{user.isActive}</td>
            <td className={"no-wrap align-center"}>
                <ButtonIconResetPassword
                    disabled={disableEdit}
                    inputDescription={`for ${user.firstName} ${user.lastName}`}
                    onClick={(event) => handleResetPasswordClick(event, user.email)}
                />
                <ButtonIconEdit
                    disabled={disableEdit}
                    inputDescription={`for ${user.firstName} ${user.lastName}`}
                    onClick={(event) => handleEditUserClick(event, user.userId)}
                />
            </td>
        </tr>
    );
};

UserSearchResultsRow.propTypes = {
    handleResetPassword: PropTypes.func.isRequired,
    disableEdit: PropTypes.bool.isRequired,
    setEditId: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired
};

export default UserSearchResultsRow;
