import PropTypes from "prop-types";
import React from "react";

export const Layout = ({
                           children,
                       }) => {

    return (
        <div>
            {children}
        </div>
    );
};

Layout.propTypes = {
    children: PropTypes.any
};