import PropTypes from "prop-types";
import React from "react";
import Header from "./Header";
import {Main} from "./Main";
import Footer from "./Footer";

export const TemplateBasic = ({children}) => {
    return (
        <>
            <noscript>You need to enable JavaScript to run this app.</noscript>
            <Header/>
            <Main>
                {children}
            </Main>
            <Footer />
        </>
    );
};

TemplateBasic.propTypes = {
    children: PropTypes.any
};