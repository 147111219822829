import PropTypes from "prop-types";
import React from "react";
import {GridCell, gridConstants} from "../../../Components/Grid";
import {Button, InputForm, InputFormHeader, SelectField, TextField} from "../../../Components/Inputs";
import {locations} from "../../../Utilities/Location";
import {getLinkWithNewLabel} from "../../../Utilities/Location/locationUtilities";
import {getActivityStatusList} from "../../activityConstants";

const ActivitySearchFormView = ({
                                    activitySearchCriteria,
                                    handleSetActivitySearchCriteria,
                                    isLoading
                            }) => {
    const handleChangeForm = (event) => {
        let criteria = {...activitySearchCriteria};
        criteria[event.target.id] = event.target.value;
        criteria.loadSearchResults = false;

        handleSetActivitySearchCriteria(criteria);
    };

    const handleClearClick = (event) => {
        event.preventDefault();
        let criteria = {...activitySearchCriteria};
        criteria.ClearActivitySearch();
        handleSetActivitySearchCriteria(criteria);
    };

    const handleSearchClick = (event) => {
        event.preventDefault();
        let criteria = {...activitySearchCriteria};

        criteria.ClearSearch();
        handleSetActivitySearchCriteria(criteria);
    };

    return (
        <>
            <InputFormHeader
                h2={`Search Activities`}
                link1={locations.ADD_ACTIVITY_SETTINGS.link}
                link2={getLinkWithNewLabel(locations.DASHBOARD.link, "Return to the Dashboard")}
            />
            <InputForm name={"activitySearch"}>
                <TextField
                    label="Activity Title or ID"
                    medium_columns={gridConstants.column.NINE}
                    name="activityInfo"
                    onChange={handleChangeForm}
                    value={activitySearchCriteria.activityInfo}
                />
                <SelectField
                    defaultOptionValue={"-1"}
                    defaultOption={"All"}
                    includeDefaultOption
                    label={"Status"}
                    medium_columns={gridConstants.column.THREE}
                    name={"activityStatus"}
                    onChange={handleChangeForm}
                    options={getActivityStatusList()}
                    value={activitySearchCriteria.activityStatus}
                />
                <GridCell medium_columns={gridConstants.column.SIX}/>
                <GridCell medium_columns={gridConstants.column.THREE}>
                    <Button
                        disabled={isLoading}
                        label="Search"
                        name="btnSearchActivities"
                        onClick={handleSearchClick}
                        isPrimary
                    />
                </GridCell>
                <GridCell medium_columns={gridConstants.column.THREE}>
                    <Button
                        disabled={isLoading}
                        name="btnClearActivitySearch"
                        onClick={handleClearClick}
                        label="Clear"
                    />
                </GridCell>
            </InputForm>
        </>
    );
};

ActivitySearchFormView.propTypes = {
    activitySearchCriteria: PropTypes.object.isRequired,
    handleSetActivitySearchCriteria: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired
};

export default ActivitySearchFormView;
