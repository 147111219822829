import React from "react";
import {Link} from "react-router-dom";
import {Layout} from "../../Components/Layout";
import {GradeLevelsList, GradeLevelsTitle} from "../../SiteConfig/gradeLevelConstants";
import {TopicsList, TopicsTitle} from "../../SiteConfig/topicConstants";
import {useDocumentTitle} from "../../Utilities/landmarkUtilities";
import {locations} from "../../Utilities/Location";

const LandingContainer = () => {

    useDocumentTitle("Student Center Activities");

    return (
        <Layout>
            <div className="by_grade_level">
                <div className="by_grade_level__header">
                    <div className="by_grade_level__back"></div>
                    <h2>Activities by {GradeLevelsTitle}</h2>
                </div>

                <ul className="by_grade_level__options">
                    {
                        GradeLevelsList.map((gradeLevel, index) => {
                            return <li key={index}><Link to={locations.ACTIVITY_SEARCH_RESULTS.getPath(gradeLevel.id)}>{gradeLevel.value}</Link></li>;
                        })
                    }
                </ul>
            </div>
            <div className="by_topics">
                <h2>Activities by {TopicsTitle}</h2>
                <ul className="by_topics__options">
                    {
                        TopicsList.map((topic, index) => {
                            return <li key={index}><Link to={locations.ACTIVITY_SEARCH_RESULTS.getPath(topic.id)} className={`color${index + 1}`}>{topic.value}</Link></li>;
                        })
                    }
                </ul>
            </div>
        </Layout>
    );
};

export default LandingContainer;
