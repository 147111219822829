import PropTypes from "prop-types";
import React from "react";
import {getRoleList} from "../../Components/Authorize/roleConstants";
import {
    ButtonIconSave, clearInputFormErrorFields, convertInputFormToObject, inputFormIsValid,
    reinitializeInputFormFields, SelectField, TextField,
    useInputForm, ButtonIconCancel
} from "../../Components/Inputs";
import {createYesNoList} from "../../Components/Inputs/inputUtility";
import {isTrimmedStringEmpty} from "../../Utilities/commonUtilities";
import {userModel} from "../userFactory";

const EditUserRow = ({
                         handleSaveUser,
                         setEditId,
                         setWarnings,
                         user
                     }) => {
    const userForm = useInputForm(userModel(user));
    const handleCancelClick = (event) => {
        event.preventDefault();
        reinitializeInputFormFields(userForm, userModel());
        setEditId(null);
    };

    const isFormValid = () => {
        clearInputFormErrorFields(userForm);

        if (isTrimmedStringEmpty(userForm.firstName.value)) {
            userForm.firstName.setError("First name is required.");
        }

        if (isTrimmedStringEmpty(userForm.lastName.value)) {
            userForm.lastName.setError("Last name is required.");
        }

        if (isTrimmedStringEmpty(userForm.email.value)) {
            userForm.email.setError("Email is required.");
        }

        if (isTrimmedStringEmpty(userForm.roleId.value.toString())) {
            userForm.roleId.setError("Role is required.");
        }

        if (isTrimmedStringEmpty(userForm.isActive.value)) {
            userForm.isActive.setError("Status is required.");
        }

        let isValid = inputFormIsValid(userForm);

        if (!isValid)
            setWarnings(["Missing required items.  Please review your responses and try submitting again."]);

        return isValid;
    };

    const handleSaveClick = (event) => {
        event.preventDefault();
        if (isFormValid()) {
            const updatedUser = convertInputFormToObject(userForm);
            handleSaveUser(updatedUser, () => reinitializeInputFormFields(userForm, userModel()));
        }
    };

    return (
        <tr>
            <td><TextField {...userForm.firstName} showOnlyForSr/></td>
            <td><TextField {...userForm.lastName} showOnlyForSr/></td>
            <td><TextField {...userForm.email} showOnlyForSr/></td>
            <td>
                <SelectField {...userForm.roleId}
                             defaultOption={`Select a Role`}
                             defaultOptionValue={""}
                             includeDefaultOption
                             options={getRoleList()}
                             showOnlyForSr
                />
            </td>
            <td>
                <SelectField {...userForm.isActive}
                             defaultOption={`Select a Status`}
                             defaultOptionValue={""}
                             includeDefaultOption
                             options={createYesNoList(false)}
                             showOnlyForSr
                />
            </td>
            <td className={"no-wrap align-center"}>
                <ButtonIconSave
                    inputDescription={`for ${user.firstName} ${user.lastName}`}
                    onClick={handleSaveClick}
                />
                <ButtonIconCancel
                    inputDescription={`for ${user.firstName} ${user.lastName}`}
                    onClick={handleCancelClick}
                />
            </td>
        </tr>
    );
};

EditUserRow.propTypes = {
    handleSaveUser: PropTypes.func.isRequired,
    setEditId: PropTypes.func.isRequired,
    setWarnings: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired
};

export default EditUserRow;
