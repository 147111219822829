import PropTypes from "prop-types";
import React from "react";
import gridConstants from "./gridConstants";

const GridFlexSpacer = ({ children, isHiddenSmall }) => {
    const hideForPrintClass = (!children) ? "hidden-print" : "";
    const hideForSmallClass = (isHiddenSmall) ? gridConstants.class.HIDE_SMALL : "";

    return <div className={`${gridConstants.class.CELL} ${gridConstants.column.AUTO} ${hideForPrintClass} ${hideForSmallClass}`}>{children}</div>;
};

GridFlexSpacer.propTypes = {
    children: PropTypes.any,
    isHiddenSmall: PropTypes.bool,
};

export default GridFlexSpacer;
