const NODE_ENV = process.env.NODE_ENV;
const NODE_ENV_PROD = "production";
const NODE_ENV_TEST = "test";
const SERVER_API_MODE = "SERVER_API_MODE";
const MOCK_API_MODE = "MOCK_API_MODE";
const SERVER_URL = NODE_ENV === NODE_ENV_PROD ? "" : "https://localhost:46035";
const CONFIG_URL = NODE_ENV === NODE_ENV_PROD ? "" : "http://localhost:3000";

export const DocumentTitlePostFix = "FCRR DSCA";

export const api = {
    environment: {
        MODE: NODE_ENV === NODE_ENV_PROD ? SERVER_API_MODE :
            NODE_ENV === NODE_ENV_TEST ? MOCK_API_MODE : SERVER_API_MODE, // mock or server
        DELAY: NODE_ENV_TEST ? 0 : 50
    },
    RETRIES: 10,
    modes: {
        MOCK: MOCK_API_MODE,
        SERVER: SERVER_API_MODE
    },
    URL: `${SERVER_URL}/api/`,
    CONFIG_URL
};