import React from "react";
import FCRR_logo from "../../images/FCRR_logo.svg";

export const Header = () => {

    return (
        <header className="mainHeader">
            <div className="mainHeader__content">
                <div className="mainHeader__courseTitle">
                    Student Center Activities
                </div>
                <div className="mainHeader__logo">
                    <img src={FCRR_logo} alt="" />
                </div>
            </div>
        </header>
    );
};
export default React.memo(Header);