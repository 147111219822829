import { ASSET_TYPE, ASSET_TYPE_NAMES } from "./mediaAssetTypeConstants";

export const assetModel = (
  { name,
    assetType,
    assetTypeOutput,
    dateModified,
    dateModifiedOutput,
    isUsed,
    lockedActivities
  }
) => ({
  name: name || "",
  assetType: assetType || ASSET_TYPE.Folder,
  assetTypeOutput: assetTypeOutput || ASSET_TYPE_NAMES.Folder,
  dateModified: dateModified || "",
  dateModifiedOutput: dateModifiedOutput || "",
  isUsed: isUsed || false,
  lockedActivities: lockedActivities || []
});
