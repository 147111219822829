import PropTypes from 'prop-types';
import React from 'react';
import { HtmlDisplay } from "../Display";
import { HelpText, helpTextSuffix } from "./HelpText";

const FileUploadButton = ({ disabled, helpText, isCollapsed, label, name, onChange, allowMultiple = false }) => {
    return (
        <>
            <input type="file"
                aria-describedby={`${name}${helpTextSuffix}`}
                data-testid={name}
                name={name}
                id={name}
                onChange={onChange}
                multiple={allowMultiple}
                disabled={disabled}
            />
            <label htmlFor={name} className={`button ${!isCollapsed ? "expanded" : ""} ${disabled ? "is-disabled" : ""}`}>
                <HtmlDisplay html={label} isInline />
            </label>
            <HelpText name={name} helpText={helpText} isCentered />
        </>
    );
};

FileUploadButton.propTypes = {
    allowMultiple: PropTypes.bool,
    disabled: PropTypes.bool,
    helpText: PropTypes.string,
    isCollapsed: PropTypes.bool,
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func,
};

export default FileUploadButton;