import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import "../../sass/modules/_activity.scss";
import { isNullOrUndefined } from "../../Utilities/commonUtilities";

const ActivityWebGlView = ({
                               activityDetail,
                               includeStopAllAudioOnUnload = true,
                               scriptId = "activityScript",
                               webGlName = "ActivityPlayer"
                           }) => {
    const [instance, setInstance] = useState(null);
    const buildUrl = `/config/${webGlName}/Build`;
    const frameworkUrl = `${buildUrl}/${webGlName}.framework.js`;

    useEffect(() => {
        const scriptExists = document.getElementById(scriptId);

        if (activityDetail && !scriptExists) {
            const loadActivity = () => {

                window.activityId = activityDetail.id;

                const hideFullScreenButton = "";
                const loaderUrl = `${buildUrl}/${webGlName}.loader.js`;

                const config = {
                    dataUrl: `${buildUrl}/${webGlName}.data`,
                    frameworkUrl: frameworkUrl,
                    codeUrl: `${buildUrl}/${webGlName}.wasm`,
                    streamingAssetsUrl: "StreamingAssets",
                    companyName: "Florida Center for Interactive Media",
                    productName: "FCRR Digital SCAs",
                    productVersion: "1.0",
                };

                const container = document.querySelector("#unity-container");
                const canvas = document.querySelector("#unity-canvas");
                const loadingCover = document.querySelector("#loading-cover");
                const progressBarEmpty = document.querySelector("#unity-progress-bar-empty");
                const progressBarFull = document.querySelector("#unity-progress-bar-full");
                const fullscreenButton = document.querySelector("#unity-fullscreen-button");
                const spinner = document.querySelector('.spinner');

                const canFullscreen = (function () {
                    for (const key of [
                        'exitFullscreen',
                        'webkitExitFullscreen',
                        'webkitCancelFullScreen',
                        'mozCancelFullScreen',
                        'msExitFullscreen',
                    ]) {
                        if (key in document) {
                            return true;
                        }
                    }
                    return false;
                }());

                if (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)) {
                    container.className = "unity-mobile";
                    config.devicePixelRatio = 1;
                }
                loadingCover.style.display = "";


                const script = document.createElement('script');
                script.id = scriptId;
                script.src = loaderUrl;
                script.onload = () => {
                    createUnityInstance(canvas, config, (progress) => {
                        spinner.style.display = "none";
                        progressBarEmpty.style.display = "";
                        progressBarFull.style.width = `${100 * progress}%`;
                    }).then((unityInstance) => {
                        setInstance(unityInstance);
                        loadingCover.style.display = "none";
                        if (canFullscreen) {
                            if (!hideFullScreenButton) {
                                fullscreenButton.style.display = "";
                            }
                            fullscreenButton.onclick = () => {
                                instance.SetFullscreen(1);
                            };
                        }
                    }).catch((message) => {
                        alert(message);
                    });
                }
                document.body.appendChild(script);
            }

            loadActivity();
        }

        return () => {
            if (instance) {
                if(includeStopAllAudioOnUnload)
                    instance.SendMessage('ActivitySceneController', 'StopAllAudio');
                let scriptElement = document.getElementById(scriptId);
                document.body.removeChild(scriptElement);

                const frameworkScripts = document.querySelectorAll(`script[src='${frameworkUrl}']`);
                const convertedNodeList = Array.from(frameworkScripts)
                for(let scriptTag of convertedNodeList) {
                    scriptTag.parentNode.removeChild(scriptTag);
                }
            }
        };

    }, [activityDetail, instance]); // eslint-disable-line react-hooks/exhaustive-deps

    if (!activityDetail)
        return null;

    const getHeaderHeight = () => {

        var headerHeight = 0;

        const mainHeaderElement = document.getElementsByClassName('mainHeader');
        if(!isNullOrUndefined(mainHeaderElement) && !isNullOrUndefined(mainHeaderElement[0]))
            headerHeight += mainHeaderElement[0].clientHeight;

        const byGradeLevelElement = document.getElementsByClassName('by_grade_level__header');
        if(!isNullOrUndefined(byGradeLevelElement) && !isNullOrUndefined(byGradeLevelElement[0]))
            headerHeight += byGradeLevelElement[0].clientHeight;

        return headerHeight;
    }

    const noDisplayStyle = {display: "none"};
    return (
        <>
            <div className='unity-wrapper'>
                <div id="unity-container" className="unity-desktop">
                <canvas id="unity-canvas" style={{height: `calc(100vh - ${getHeaderHeight()}px)`}} tabIndex={`-1`}></canvas>
                </div>
            </div>
            <div id="loading-cover" style={noDisplayStyle}>
                <div id="unity-loading-bar">
                    <div id="unity-progress-bar-empty" style={noDisplayStyle}>
                        <div id="unity-progress-bar-full"></div>
                    </div>
                    <div className="spinner"></div>
                </div>
            </div>
            <div id="unity-fullscreen-button" style={noDisplayStyle}></div>
        </>
    );
};

ActivityWebGlView.propTypes = {
    activityDetail: PropTypes.object.isRequired,
    includeStopAllAudioOnUnload: PropTypes.bool,
    scriptId: PropTypes.string,
    webGlName: PropTypes.string
}

export default ActivityWebGlView;
