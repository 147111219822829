import PropTypes from "prop-types";
import React from "react";
import { locations } from "../../Utilities/Location";
import ForgotPasswordView from "./ForgotPasswordView";
import passwordApi from "./passwordApi";

const ForgotPasswordContainer = ({
    handleApiCall,
    isLoading,
    setWarnings,
    redirect,
}) => {

    const handleEmailAsync = async (email) => {
        return handleApiCall(() => passwordApi.forgotPassword(email),
            redirect(locations.SIGN_IN.path));
    };

    return <ForgotPasswordView
        handleEmailAsync={handleEmailAsync}
        isLoading={isLoading}
        setWarnings={setWarnings} />;
};

ForgotPasswordContainer.propTypes = {
    handleApiCall: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    redirect: PropTypes.func.isRequired,
    setWarnings: PropTypes.func.isRequired,
};

export default ForgotPasswordContainer;
