import {GradeLevels, GradeLevelsTitle} from "../SiteConfig/gradeLevelConstants";
import {Topics, TopicsTitle} from "../SiteConfig/topicConstants";

export const getActivityType = (activity) => {
    if(checkActivityType(Topics, activity)) return Topics;

    if(checkActivityType(GradeLevels, activity)) return GradeLevels;

    return null;
}

export const getActivityTitle = (activity) => {
    if(checkActivityType(Topics, activity)) return TopicsTitle;

    if(checkActivityType(GradeLevels, activity)) return GradeLevelsTitle;

    return "";
}

const checkActivityType = (activityType, activity) => {
    return !(!activityType[activity] || activityType[activity] === "");
}