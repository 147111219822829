import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { PageTitle } from "../../../Components/Landmarks";
import { locations } from "../../../Utilities/Location";
import activitySearchApi from "./activitySearchApi";
import ActivitySearchFormView from "./ActivitySearchFormView";
import ActivitySearchResultsView from "./ActivitySearchResultsView";

const ActivitySearchContainer = ({
                                     activitySearchCriteria,
                                     handleApiCall,
                                     handleSetActivitySearchCriteria,
                                     isLoading,
                                     redirect
                             }) => {
    const [activities, setActivities] = useState(null);
    const [totalRecords, setTotalRecords] = useState(0);

    const loadActivitiesResults = (results) => {
        setActivities(results.data);
        setTotalRecords(results.totalRecords);
    };

    const loadActivities = () => {
        handleApiCall(() => activitySearchApi.getActivities(activitySearchCriteria), loadActivitiesResults);
    };

    useEffect(() => {
        if (!activitySearchCriteria) return;

        if (activitySearchCriteria.loadSearchResults)
            loadActivities();

    }, [activitySearchCriteria]); // eslint-disable-line react-hooks/exhaustive-deps

    if (!activitySearchCriteria) return null;

    return (
        <>
            <PageTitle h1={locations.MANAGE_ACTIVITIES.label} isCentered />

            <ActivitySearchFormView
                activitySearchCriteria={activitySearchCriteria}
                handleSetActivitySearchCriteria={handleSetActivitySearchCriteria}
                isLoading={isLoading}
            />

            <ActivitySearchResultsView
                activities={activities}
                activitySearchCriteria={activitySearchCriteria}
                handleSetActivitySearchCriteria={handleSetActivitySearchCriteria}
                isLoading={isLoading}
                redirect={redirect}
                totalRecords={totalRecords}
            />
        </>
    );
};

ActivitySearchContainer.propTypes = {
    activitySearchCriteria: PropTypes.object,
    handleApiCall: PropTypes.func.isRequired,
    handleSetActivitySearchCriteria: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    redirect: PropTypes.func.isRequired,
};

export default ActivitySearchContainer;