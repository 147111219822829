import { useEffect } from "react";

export function useDocumentTitle(title) {
    const defaultTitle = "DSCA"

    useEffect(() => {
        document.title = title ? title : defaultTitle;
    }, [title]);
}

export function refocusOnPageChange() {
    const resetFocusItem = document.getElementsByTagName("h1")[0];
    if(!resetFocusItem) return;
    resetFocusItem.style.outline = "none";
    resetFocusItem.focus();
}