import PropTypes from "prop-types";
import React from "react";
import {isArrayNullOrEmpty} from "../../Utilities/Types/arrayUtilities";

const NestAdInfinitum = ({
                             array,
                             children,
                         }) => {

    const newArray = array.slice(1);
    const Parent = !isArrayNullOrEmpty(array) ? array[0] : React.Fragment;

    return (
        <Parent>
            {
                !isArrayNullOrEmpty(newArray) &&
                <NestAdInfinitum array={newArray}>
                    {children}
                </NestAdInfinitum>
            }
            {
                isArrayNullOrEmpty(newArray) &&
                children
            }
        </Parent>
    );
};

NestAdInfinitum.propTypes = {
    array: PropTypes.array,
    children: PropTypes.any,
};

export default React.memo(NestAdInfinitum);