import PropTypes from "prop-types";
import React from "react";
import {GridCell, gridConstants, GridContainer, GridFlexSpacer} from "../Grid";
import {Button} from "../Inputs";

const PageNavigation = ({
                            disablePrimary = false,
                            handlePrimaryClick,
                            handleSecondaryClick,
                            labelPrimary,
                            labelSecondary,
                        }) => {

    const isCollapsed = !labelSecondary || !labelPrimary;
    const className = isCollapsed ? "text-center" : "";

    return (
        <GridContainer hasMarginX hasMarginY isFlush isReverseOrder>
            {
                !labelSecondary &&
                <GridFlexSpacer/>
            }
            {
                labelPrimary &&
                <GridCell medium_columns={gridConstants.column.FOUR} className={className}>

                    <Button onClick={handlePrimaryClick} label={labelPrimary} isPrimary isCollapsed={isCollapsed}
                            disabled={disablePrimary}
                            name={`btnPageNavigationPrimary`}/>
                </GridCell>
            }
            <GridFlexSpacer/>
            {
                labelSecondary &&
                <GridCell medium_columns={gridConstants.column.FOUR} className={className}>
                    <Button onClick={handleSecondaryClick} label={labelSecondary} isCollapsed={isCollapsed}
                            name={`btnPageNavigationSecondary`}/>
                </GridCell>
            }
            {
                !labelPrimary &&
                <GridFlexSpacer/>
            }
        </GridContainer>
    );
};

PageNavigation.propTypes = {
    disablePrimary: PropTypes.bool,
    handlePrimaryClick: PropTypes.func,
    handleSecondaryClick: PropTypes.func,
    labelPrimary: PropTypes.string,
    labelSecondary: PropTypes.string,
};

export default React.memo(PageNavigation);