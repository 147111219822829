import Link from "../../Components/Link";
import {locations} from "../../Utilities/Location";
import {registerModel} from "./registerFactory";
import PropTypes from "prop-types";

import {
    Button,
    clearInputFormErrorFields,
    convertInputFormToObject,
    EmailField,
    InputForm,
    inputFormIsValid,
    PasswordField,
    TextField,
    useInputForm,
} from "../../Components/Inputs";
import {PageTitle} from "../../Components/Landmarks";
import {ContentSmall} from "../../Components/Layout";
import {GridCell} from "../../Components/Grid";
import {isTrimmedStringEmpty} from "../../Utilities/commonUtilities";
import {isValidEmail} from "../../Components/Inputs/inputUtility";

const RegisterView = ({
                          handleRegisterUser,
                          setWarnings
                      }) => {
    const registerForm = useInputForm(registerModel());

    const isFormValid = () => {
        clearInputFormErrorFields(registerForm);

        if (!isTrimmedStringEmpty(registerForm.password.value)
            && registerForm.password.value !== registerForm.confirmPassword.value)
            registerForm.password.setError("Password must match confirmation field.");

        if (isTrimmedStringEmpty(registerForm.confirmPassword.value))
            registerForm.confirmPassword.setError("Confirm password is required.");

        if (isTrimmedStringEmpty(registerForm.password.value))
            registerForm.password.setError("Password is required.");

        if (isTrimmedStringEmpty(registerForm.email.value))
            registerForm.email.setError("Email is required.");
        else if (!isValidEmail(registerForm.email.value))
            registerForm.email.setError("Email is not valid.");

        if (isTrimmedStringEmpty(registerForm.lastName.value))
            registerForm.lastName.setError("Last name is required.");

        if (isTrimmedStringEmpty(registerForm.firstName.value))
            registerForm.firstName.setError("First name is required.");

        let isValid = inputFormIsValid(registerForm);

        if (!isValid)
            setWarnings(["Missing required items.  Please review your responses and try submitting again."]);

        return isValid;
    };

    const handleClickSubmit = () => {
        if (isFormValid()) {
            const user = convertInputFormToObject(registerForm);
            handleRegisterUser(user);
        }
    };

    return (
        <ContentSmall>
            <PageTitle h1={locations.REGISTER.label} hasBorder/>

            <InputForm name={"registerModel"}>

                <TextField {...registerForm.firstName}/>

                <TextField {...registerForm.lastName} />

                <EmailField {...registerForm.email} />

                <PasswordField {...registerForm.password}/>

                <PasswordField {...registerForm.confirmPassword} />

                <GridCell>
                    <Button isPrimary label={`Submit`} name={`btnSubmit`} onClick={handleClickSubmit}/>
                </GridCell>

                <GridCell>
                    <p className={`align-right`}>
                        <Link {...locations.SIGN_IN.link} />
                    </p>
                </GridCell>
            </InputForm>
        </ContentSmall>
    );
};

RegisterView.propTypes = {
    handleRegisterUser: PropTypes.func.isRequired,
    setWarnings: PropTypes.func.isRequired
};

export default RegisterView;