const defaultOptions = {
    closeButton: true,
	closeHtml: '<span aria-label="Click to close."></span>',
    preventDuplicates: false,
    newestOnTop: true,
    positionClass: "toast-top-center",
    showDuration: 0,
    hideDuration: 0,
	target: 'main',
}
const persistentOptions = {
    ...defaultOptions,
    timeOut: 0,
    extendedTimeOut: 0,
}

const timeoutOptions = {
    ...defaultOptions,
    timeOut: 10000,
    extendedTimeOut: 10000,
    progressBar: true
}

const toastrOptionsProps = {
    ServerError: persistentOptions,
    Error: persistentOptions,
    Success: timeoutOptions,
    Info: timeoutOptions,
    Warning: persistentOptions,
};
export default toastrOptionsProps;

// more options - http://codeseven.github.io/toastr/demo.html
// tapToDismiss: true,
// toastClass: 'toast',
// containerId: 'toast-container',
// debug: false,
//
// showMethod: 'fadeIn', //fadeIn, slideDown, and show are built into jQuery
// showDuration: 300,
// showEasing: 'swing', //swing and linear are built into jQuery
// onShown: undefined,
// hideMethod: 'fadeOut',
// hideDuration: 1000,
// hideEasing: 'swing',
// onHidden: undefined,
// closeMethod: false,
// closeDuration: false,
// closeEasing: false,
// closeOnHover: true,
//
// extendedTimeOut: 1000,
// iconClasses: {
//     error: 'toast-error',
//     info: 'toast-info',
//     success: 'toast-success',
//     warning: 'toast-warning'
// },
// iconClass: 'toast-info',
// positionClass: 'toast-top-right',
// timeOut: 5000, // Set timeOut and extendedTimeOut to 0 to make it sticky
// titleClass: 'toast-title',
// messageClass: 'toast-message',
// escapeHtml: false,
// target: 'body',
// closeHtml: '<button type="button">&times;</button>',
// closeClass: 'toast-close-button',
// newestOnTop: true,
// preventDuplicates: false,
// progressBar: false,
// progressClass: 'toast-progress',
// rtl: false