import {PageTitle} from "../../Components/Landmarks";
import {ContentSmall} from "../../Components/Layout";
import Link from "../../Components/Link";
import checkMark from "../../images/checkmark.svg";
import React from "react";
import {locations} from "../../Utilities/Location";

const SuccessfulRegisterContainer = () => {
    return (
        <ContentSmall>
            <PageTitle h1={locations.SUCCESSFUL_REGISTER.label} />
            <div className={`center-text`}>
                <p><img src={checkMark} alt={`Registration Complete!`} /></p>
                <p>Thank you for registering!</p>
                <p>You will be contacted by a site administrator for next steps.  Until then, enjoy your day!</p>
                <p>Return to <Link {...locations.SIGN_IN.link} />.</p>
            </div>
        </ContentSmall>
    );
}
export default SuccessfulRegisterContainer;
