import PropTypes from "prop-types";
import React from "react";
import gridConstants from "./gridConstants";

const GridCellTemplate = ({
                              children,
                              className = "",
                              isList,
                              large_columns = "",
                              large_offset = "",
                              medium_columns = "",
                              medium_offset = "",
                              small_columns = "",
                              small_offset = "",
                              xsmall_columns = ""
                          }) => {
    let classes = `clearfix ${gridConstants.class.CELL}`;
    classes += className ? ` ${className}` : ``;
    classes += xsmall_columns ? ` xsmall-${xsmall_columns}` : ` xsmall-12`;
    classes += small_columns ? ` small-${small_columns}` : ``;
    classes += medium_columns ? ` medium-${medium_columns}` : ``;
    classes += large_columns ? ` large-${large_columns}` : ``;
    classes += small_offset ? ` small-offset-${small_offset}` : ``;
    classes += medium_offset ? ` medium-offset-${medium_offset}` : ``;
    classes += large_offset ? ` large-offset-${large_offset}` : ``;

    if (isList)
        return <li className={classes}> {children} </li>;

    return <div className={classes}>{children}</div>;
};

export const GridCell = (props) => GridCellTemplate({...props, isList: false});
export const GridListItem = (props) => GridCellTemplate({...props, isList: true});

const props = {
    children: PropTypes.any,
    className: PropTypes.string,
    large_columns: PropTypes.string,
    large_offset: PropTypes.string,
    medium_columns: PropTypes.string,
    medium_offset: PropTypes.string,
    small_columns: PropTypes.string,
    small_offset: PropTypes.string,
    xsmall_columns: PropTypes.string,
};


GridCell.propTypes = props;
GridListItem.propTypes = props;
GridCellTemplate.propTypes = {
    ...props,
    isList: PropTypes.bool,
};
