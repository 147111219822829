import PropTypes from "prop-types";
import React from "react";
import { Link as RouterLink } from "react-router-dom";

export const Link = ({ className, label, to, handleClick = () => {} }) => {
    if (!label) return <> </>;

    return (
        <RouterLink to={to} className={className} onClick={handleClick}>
            {label}
        </RouterLink>
    );
};

Link.propTypes = {
    className: PropTypes.string,
    label: PropTypes.any,
    to: PropTypes.string,
    handleClick: PropTypes.func
};