import {animateScroll as scroll} from "react-scroll";
import {isString} from "./Types/typeUtilities";

export const emptyGuid = "00000000-0000-0000-0000-000000000000";

export const dashNumberRange = "–";
export const dashPause = "—";

export function isGuidEmpty(guid) {
    return !guid || guid === emptyGuid;
}

export function isTrimmedStringEmpty(value) {
    return !isString(value) || value.trim().length === 0;
}

export function isNullOrUndefined(value) {
    return value === null || value === undefined;
}

export function createRedirectUrl(pathName, searchString) {
    return searchString ? pathName + searchString : pathName;
}

export function convertCamelCaseToSpaceDelimited(string = "", makeProperCase = false) {
    if (isTrimmedStringEmpty(string))
        return "";

    if (makeProperCase) {
        string = string.charAt(0).toUpperCase() + string.substring(1);
    }

    const regex = /([A-Z])([A-Z])([a-z])|([a-z])([A-Z])/g;
    return string.replace(regex, "$1$4 $2$3$5");
}

export function convertUnderscoreToDash(string) {
    let result = string.replace(/__/gi, "- ");
    return result.replace(/_/gi, "-");
}

export function convertToNumber(value = "not a number") {
    if (isString(value))
        value = value.trim();

    const conversion = Number(value);
    return isNaN(conversion) ? undefined : conversion;
}

export function convertToBoolean(value = "not a bool") {
    if (isNullOrUndefined(value))
        return false;

    return value.toString().toLowerCase().trim() === "true";
}

export const scrollToTop = () => {
    scroll.scrollToTop({
        duration: 0,
        smooth: "easeOutQuint",
    });
};

export function getSiteProtocolDomain() {
    return `${document.location.origin}/#`;
}