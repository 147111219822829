import PropTypes from "prop-types";
import RegisterView from "./RegisterView";
import {locations} from "../../Utilities/Location";
import registerApi from "./registerApi";

const RegisterContainer = ({
                               handleApiCall,
                               redirect,
                               setWarnings,
                           }) => {
    const handleRegisterUser = (registerModel) => {
        handleApiCall(() => registerApi.attemptRegister(registerModel).then(() => {
            redirect(locations.SUCCESSFUL_REGISTER.path);
        }));
    };

    return (
        <RegisterView
            handleRegisterUser={handleRegisterUser}
            setWarnings={setWarnings}
        />
    );
};

RegisterContainer.propTypes = {
    handleApiCall: PropTypes.func.isRequired,
    redirect: PropTypes.func.isRequired,
    setWarnings: PropTypes.func.isRequired
};

export default RegisterContainer;