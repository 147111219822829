import {isNullOrUndefined} from "./commonUtilities";

export function toDangerouslySetHtml(htmlString) {
    return { __html: htmlString };
}

export function convertLineBreaksToBreakTags(rawString) {
    if (!rawString)
        return "";

    return rawString.replace(/(?:\r\n|\r|\n)/g, "<br>");
}

/**
 * @param {number} min - The lower bounds of the random number.
 * @param {number} max - The upper bounds of the random number.
 * @returns {number} - A random integer between min (inclusive) and max (inclusive)
 * Using Math.round() will give you a non-uniform distribution!
 */
export function getRandomInt(min = 1, max = 1000) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function openInNewTab(url)
{
    if(isNullOrUndefined(url))
        return;

    const prefix = url.startsWith("/") ? "/#" : "/#/";

    // eslint-disable-next-line no-restricted-globals
    open(`${prefix}${url}`);
}