import PropTypes from "prop-types";
import React from "react";
import {toDangerouslySetHtml} from "../../Utilities/htmlUtilities";

const HtmlDisplay = ({className, disabled, name, html, isInline, isUpdatedLive, onClick}) => {
    const classes = `${className} ${
        onClick ? "htmlDisplay--editable is-clickable" : ""
    }`;

    const props = {
        "aria-disabled": disabled,
        "aria-live": isUpdatedLive ? "polite" : "off",
        className: `htmlDisplay is-dangerouslySetHtml ${classes}`,
        id: name,
        "data-testid": name,
        onClick: disabled ? null : onClick,
        tabIndex: onClick ? "0" : undefined,
        dangerouslySetInnerHTML: toDangerouslySetHtml(html),
    };

    return isInline ? <span {...props} /> : <div {...props} aria-disabled={disabled} />;
};

HtmlDisplay.propTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool,
    html: PropTypes.string.isRequired,
    isUpdatedLive: PropTypes.bool,
    isInline: PropTypes.bool,
    name: PropTypes.string,
    onClick: PropTypes.func,
};

HtmlDisplay.defaultProps = {
    className: "",
    html: "",
};

export default React.memo(HtmlDisplay);
