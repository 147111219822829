import PropTypes from "prop-types";
import React, { Fragment } from "react";
import { HtmlDisplay } from "../../Components/Display";
import { GridCell, gridConstants, GridContainer } from "../../Components/Grid";
import Link from "../../Components/Link";
import { locations } from "../../Utilities/Location";

const MediaAssetsDirectoryView = ({
    mediaAssetsPath,
    isEditing
}) => {

    const assets = ["media-assets", ...mediaAssetsPath?.split(`/`).filter(a => a !== ``)];

    const getLink = (index) => {
        return index === 0 ? `${locations.MEDIA_ASSETS_REDIRECT.getPath("")}` : `${locations.MEDIA_ASSETS_REDIRECT.getPath("")}${assets.slice(1, index + 1).join("/")}/`;
    };

    return (
        <GridContainer isReverseOrder className={`form__header`} hasMarginX>
            <GridCell large_columns={gridConstants.column.ZERO} className={`h2 align-right`}>
                {`Directory: `}
                {
                    assets?.map((assetName, index) => {
                        const linkCount = index + 1;
                        return (<Fragment key={index}>
                            {` / `}
                            {
                                (!isEditing && linkCount < assets.length) &&
                                <Link
                                    to={getLink(index)}
                                    label={assetName}
                                    className={`horizontal`}
                                />
                            }
                            {
                                (isEditing || linkCount === assets.length) &&
                                <>
                                    {assetName}
                                </>
                            }
                        </Fragment>);
                    })
                }
                <HtmlDisplay html={` | `} isInline className={`navigation-pipe`} />
                <Link to={locations.DASHBOARD.path} label={`Return to the Dashboard`} />
            </GridCell>
        </GridContainer>
    );

}

MediaAssetsDirectoryView.propTypes = {
    mediaAssetsPath: PropTypes.string.isRequired,
    isEditing: PropTypes.bool.isRequired
};

export default MediaAssetsDirectoryView;



