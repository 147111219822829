export const registerModel = ({
                                  firstName,
                                  lastName,
                                  email,
                              } = {}) => ({
    firstName,
    lastName,
    email,
    password: "",
    confirmPassword: "",
});