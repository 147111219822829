import React from "react";
import FSU_seal from "../../images/FSU_seal.svg";

const Footer = () => {

    return (
        <footer className="mainFooter">
            <div className="mainFooter__content">
                <address>
                    Florida Center for Reading Research<br/>
                    2010 Levy Avenue, Suite 100<br/>
                    Tallahassee, FL 32310
                </address>
                <div className="mainFooter__seal">
                    <img src={FSU_seal} alt="Florida State University Seal" />
                </div>
            </div>
        </footer>
    );
};

export default React.memo(Footer);
