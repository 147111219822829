import PropTypes from "prop-types";
import React from "react";
import {isNullOrUndefined} from "../../Utilities/commonUtilities";
import {partialOptionIndexInArray} from "../../Utilities/Types/arrayUtilities";
import {InputLayout} from "./InputLayout";
import {appendedOtherValue, createFakeEvent, generateOtherValue, isValueInArray,} from "./inputUtility";
import {TextField} from "./TextField";
import {notificationSuffix} from "./Notification";
import {helpTextSuffix} from "./HelpText";

export const SelectField = ({
                                defaultOption,
                                disabled,
                                helpText,
                                includeDefaultOption = true,
                                label,
                                medium_columns,
                                name,
                                onChange,
                                options,
                                value = "",
                                error,
                                responseFeedback,
                                defaultOptionValue = "",
                                listHasTextField = [],
                                showOnlyForSr = false
                            }) => {

    value = isNullOrUndefined(value) ? "" : value.toString();

    const handleUpdate = (selectedValue, otherValue) => {
        const value = isValueInArray(listHasTextField, selectedValue) ? generateOtherValue(selectedValue, otherValue) : selectedValue;
        onChange(createFakeEvent(value, `${name}`));
    };

    if (!defaultOption)
        defaultOption = "Select an Option";

    let selectListOptions = [];
    if (options && options.length > 0) {
        selectListOptions = options;
    }

    const otherSelectListValueIndex = partialOptionIndexInArray(listHasTextField, value.toString(), false);
    const hasTextField = otherSelectListValueIndex > -1;

    const selectListValue = hasTextField ? listHasTextField[otherSelectListValueIndex] : value;
    const otherTextValue = hasTextField ? appendedOtherValue(value, selectListValue) : "";

    return (
        <InputLayout
            disabled={disabled}
            helpText={helpText}
            label={label}
            medium_columns={medium_columns}
            name={name}
            error={error}
            responseFeedback={responseFeedback}
            showOnlyForSr={showOnlyForSr}
        >
            <select
                aria-disabled={disabled}
                aria-invalid={!!error}
                aria-errormessage={`${name}${notificationSuffix}`}
                aria-describedby={`${name}${helpTextSuffix}`}
                name={name}
                id={name}
                data-testid={name}
                value={selectListValue}
                onBlur={(event) => handleUpdate(event.target.value, otherTextValue)}
                onChange={onChange}
                disabled={disabled}
            >
                {includeDefaultOption && <option value={defaultOptionValue}>{defaultOption}</option>}
                {selectListOptions.map((listItem, index) => {
                    return (
                        <option key={`${name}${index}`} value={listItem.value}>
                            {listItem.text}
                        </option>);
                })
                }
            </select>
            {
                hasTextField &&
                <TextField
                    label={`Specify Other Text`}
                    name={`${name}_text`}
                    disabled={disabled}
                    onChange={(event) => handleUpdate(selectListValue, event.target.value)}
                    value={otherTextValue}
                />
            }
        </InputLayout>
    );
};

SelectField.propTypes = {
    defaultOption: PropTypes.string,
    disabled: PropTypes.bool,
    helpText: PropTypes.string,
    includeDefaultOption: PropTypes.bool,
    label: PropTypes.string.isRequired,
    medium_columns: PropTypes.string,
    name: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(PropTypes.object),
    onChange: PropTypes.func,
    value: PropTypes.any,
    error: PropTypes.string,
    responseFeedback: PropTypes.string,
    defaultOptionValue: PropTypes.string,
    listHasTextField: PropTypes.array,
    showOnlyForSr: PropTypes.bool
};

