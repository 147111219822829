import {
    apiConstants,
    config,
    mockResolveApiCall,
    requestOptions,
    requestTypes,
    sendRequest,
} from "../../Utilities/Api";

//************************************************** STANDARD API SERVER CALLS ****************************************

class ServerUserMgmtApi {
    static getUsers(userSearchCriteria) {
        return sendRequest(
            requestOptions(`${config.URL}admin/users/`,
                requestTypes.POST,
                JSON.stringify(userSearchCriteria),
            ),
        );
    }

    static updateUser(user) {
        return sendRequest(
            requestOptions(
                `${config.URL}admin/users/`,
                requestTypes.PUT,
                JSON.stringify(user),
                apiConstants.user.UPDATE_USER
            )
        );
    }

    static forgotPassword(email) {
        return sendRequest(
            requestOptions(`${config.URL}password/forgotPassword/`,
                requestTypes.POST,
                JSON.stringify(email),
                apiConstants.user.ADMIN_FORGOT_PASSWORD,
            ),
        );
    }
}

//************************************************** MOCK API SERVER CALLS ****************************************

class MockUserMgmtApi {
    static getUsers() {
        return mockResolveApiCall();
    }

    static updateUser() {
        return mockResolveApiCall();
    }

    static forgotPassword() {
        return mockResolveApiCall(true, apiConstants.user.ADMIN_FORGOT_PASSWORD);
    }
}

const UserMgmtApi = config.environment.MODE === config.modes.SERVER ? ServerUserMgmtApi : MockUserMgmtApi;
export default UserMgmtApi;