export const searchModel = (
    pageId,
    defaultSortByColumn,
    loadSearchResults = false,
    baseSortByColumn = "",
    recordsPerPage = 25,
) => ({
    pageId: pageId || "",
    page: 1,
    loadSearchResults: loadSearchResults,
    recordsPerPage: recordsPerPage,
    sortByColumn: defaultSortByColumn || "",
    sortDescending: false,
    baseSortByColumn: baseSortByColumn || "",
    ClearSearch: clearSearch,
    UpdateSorting: updateSorting,
    First: first,
    Last: last,
    Next: next,
    Previous: previous,
});

function clearSearch(loadSearchResults = true) {
    this.page = 1;
    this.loadSearchResults = loadSearchResults;
}

function updateSorting(columnId) {
    if (this.sortByColumn === columnId) {
        this.sortDescending = !this.sortDescending;
    } else {
        this.sortDescending = false;
        this.sortByColumn = columnId;
    }
    this.page = 1;
}

function first() {
    this.page = 1;
}

function last(totalRecords) {
    this.page = Math.ceil(totalRecords / this.recordsPerPage);
}

function next() {
    this.page = this.page + 1;
}

function previous() {
    this.page--;
}

export const sortSliceSearchData = (allData, searchCriteria) => {

    let sortProp = searchCriteria.sortByColumn;
    let sortDir = searchCriteria.sortDescending ? -1 : 1;

    allData.sort(function(a, b) {
        if (sortProp.includes("date")) {
            const firstDate = new Date(a[sortProp]);
            const secondDate = new Date(b[sortProp]);
            return firstDate > secondDate ? sortDir : secondDate > firstDate ? -1 * sortDir : 0;
        }

        return a[sortProp] > b[sortProp] ? sortDir : b[sortProp] > a[sortProp] ? -1 * sortDir : 0;
    });

    let recordsPerPage = allData.length;
    if (searchCriteria.recordsPerPage !== null) recordsPerPage = searchCriteria.recordsPerPage;

    const skip = (searchCriteria.page - 1) * recordsPerPage;
    const take = recordsPerPage + skip;

    return allData.slice(skip, take);
};
