import {createListFromObject} from "../Utilities/Types/listUtilities";
import {createObjectFromObjectPropertyNames} from "../Utilities/Types/objectUtilities";

export const ACTIVITY_STATUS = {
    Draft: 1,
    Published: 2,
    Archived: 3
};

export const ACTIVITY_STATUS_NAMES = createObjectFromObjectPropertyNames(ACTIVITY_STATUS);

export function getActivityStatusList() {
    return createListFromObject(ACTIVITY_STATUS);
}