/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from "prop-types";
import React, {createContext, useContext, useEffect, useState} from "react";
import activityApi from "../../../Containers/activityApi";
import {createActivityDetails, createActivityManifest} from "../../../Containers/activityObjectFactory";
import {useCounter} from "../../../Utilities/Reducer";
import {emptyFunction} from "../../../Utilities/testingUtilities";

export const AppContext = createContext([]);
export const useAppContext = () => useContext(AppContext);

export const AppContextProvider = ({ children }) => {
    const {count, reset, increment, decrement} = useCounter();
    const [isLoading, setIsLoading] = useState(false);
    const [returnUrl, setReturnUrl] = useState(undefined);
    const [activityDetails, setActivityDetails] = useState([]);

    const handleApiCallWithNoDim = (apiCall, thenCallback = emptyFunction, catchCallback = emptyFunction) => {
        return handleApiCall(apiCall, thenCallback, catchCallback, false);
    };

    const handleApiCall = (apiCall, thenCallback = () => {return true}, catchCallback = () => {return false}, useCounter = true) => {
        if (useCounter)
            increment();

        return new Promise((resolve) => {
            apiCall()
                .then((result) => {
                    if (useCounter)
                        decrement();

                    resolve(thenCallback(result));
                })
                .catch((error) => {
                    if (useCounter)
                        decrement();

                    resolve(catchCallback(error));
                });
        });

    };

    const loadActivityManifest = async (results) => {
        const manifest = JSON.parse(results);
        const activityManifests = manifest.map(result => createActivityManifest(result));
        const activities = [];
        for(let activity of activityManifests) {
            await handleApiCall(() => activityApi.getActivityDetails(activity.url), (result) => { activities.push(createActivityDetails(JSON.parse(result))) });
        }

        setActivityDetails(activities);
    }

    useEffect(() => {
        if (isLoading && count === 0)
            setIsLoading(false);
        if (!isLoading && count > 0)
            setIsLoading(true);
         if (count < 0)
             reset();
    }, [count, reset, isLoading]);

    useEffect(() => {
        handleApiCall(() => activityApi.getActivityManifests(true), loadActivityManifest);
    }, []);

    return (
        <AppContext.Provider
            value={{
                activityDetails,
                isLoading,
                handleApiCall,
                handleApiCallWithNoDim,
                returnUrl,
                setReturnUrl
            }}
        >
            {children}
        </AppContext.Provider>
    );
};

AppContextProvider.propTypes = {
    children: PropTypes.any,
};
