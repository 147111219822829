import PropTypes from "prop-types";
import React from "react";
import signInApi from "./signInApi";
import SignInView from "./SignInView";

const SignInContainer = ({
                             handleApiCall,
                             isLoading,
                             setLoggedInTrue,
                             setWarnings
                         }) => {

    const handleSignIn = (signInModel) => {
        handleApiCall(() => signInApi.attemptLogin(signInModel), setLoggedInTrue);
    };

    return <SignInView handleSignIn={handleSignIn} isLoading={isLoading} setWarnings={setWarnings} />;
};

SignInContainer.propTypes = {
    handleApiCall: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    setLoggedInTrue: PropTypes.func.isRequired,
    setWarnings: PropTypes.func.isRequired
};

export default SignInContainer;