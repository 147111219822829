import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import ActivityWebGlView from "../../../Components/Activity/ActivityWebGlView";
import {PageTitle} from "../../../Components/Landmarks";
import {ContentSmall, Layout} from "../../../Components/Layout";
import {Link} from "../../../Components/Link";
import button_back from "../../../images/button_back.png";
import {isNullOrUndefined} from "../../../Utilities/commonUtilities";
import {useDocumentTitle} from "../../../Utilities/landmarkUtilities";
import {locations} from "../../../Utilities/Location";
import {createActivityDetails} from "../../activityObjectFactory";
import ActivityDetailsApi from "../ActivityDetails/activityDetailsApi";

const ActivityPreviewContainer = ({
                                      activityId,
                                      handleApiCall
                                  }) => {
    const [activityDetail, setActivityDetail] = useState(null);

    useDocumentTitle(activityDetail?.title);

    useEffect(() => {
        handleApiCall(() => ActivityDetailsApi.getActivityDetails(activityId), (result) => {
            if(!result.manifestJson)
                setActivityDetail("");
            else {
                const activity = createActivityDetails(JSON.parse(result.manifestJson));
                setActivityDetail(activity);
            }
        });
    }, [activityId]);   // eslint-disable-line react-hooks/exhaustive-deps

    if (isNullOrUndefined(activityDetail))
        return null;

    if(activityDetail === "")
        return (
            <>
                <PageTitle h1={locations.PREVIEW_ACTIVITY.label} isCentered />
                <ContentSmall>
                    <h2 align={`center`}>Preview not available</h2>
                    <p align={`center`}><Link to={locations.EDIT_ACTIVITY.getPath(activityId)} label={`Return to editing the activity.`} /></p>
                </ContentSmall>
            </>
        );

    return (
        <Layout>
            <div className="by_grade_level">
                <div className="by_grade_level__header">
                    <div className="by_grade_level__back">
                        <Link to={locations.EDIT_ACTIVITY.getPath(activityId)} label={<img src={button_back} alt="Back"/>} />
                    </div>
                    <h2>{activityDetail.title}</h2>
                </div>
                <ActivityWebGlView activityDetail={activityDetail} />
            </div>
        </Layout>
    );
};

ActivityPreviewContainer.propTypes = {
    activityId: PropTypes.string.isRequired,
    handleApiCall: PropTypes.func.isRequired
}

export default ActivityPreviewContainer;
