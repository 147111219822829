import PropTypes from "prop-types";
import React from "react";
import { GridCell, gridConstants } from "../../Components/Grid";
import {
    Button,
    clearInputFormErrorFields,
    InputForm,
    inputFormIsValid,
    PasswordField,
    useInputForm
} from "../../Components/Inputs";
import { PageTitle } from "../../Components/Landmarks";
import { ContentSmall } from "../../Components/Layout";
import Link from "../../Components/Link";
import { isTrimmedStringEmpty } from "../../Utilities/commonUtilities";
import { locations } from "../../Utilities/Location";

const ResetPasswordView = ({ isLoading, handleResetAsync, setWarnings }) => {
    const form = useInputForm({ password: "", confirmPassword: "" });

    const isFormValid = () => {
        clearInputFormErrorFields(form);
        if (isTrimmedStringEmpty(form.password.value)) {
            form.password.setError("Password is required.");
        }
        if (isTrimmedStringEmpty(form.confirmPassword.value)) {
            form.confirmPassword.setError("Confirm Password is required.");
        }

        if (form.confirmPassword.value !== form.password.value) {
            form.password.setError("Password fields must match.");
        }

        let isValid = inputFormIsValid(form);

        if (!isValid)
            setWarnings(["Missing required items.  Please review your responses and try submitting again."]);

        return isValid;
    };

    const handleClickSubmit = async () => {
        if (isFormValid()) {
            handleResetAsync(form.password.value);
        }
    };

    return (
        <ContentSmall>
            <PageTitle h1={`Reset Password`} hasBorder/>

            <InputForm name={"resetPw"}>
                <PasswordField {...form.password} />

                <PasswordField {...form.confirmPassword} />

                <GridCell>
                    <Button
                        isPrimary
                        name="btnSubmit"
                        label="Submit"
                        onClick={handleClickSubmit}
                        disabled={isLoading}
                    />
                </GridCell>

                <GridCell small_columns={gridConstants.column.SIX}>
                    <p>
                        <Link {...locations.SIGN_IN.link} />
                    </p>
                </GridCell>

                <GridCell small_columns={gridConstants.column.SIX}>
                    <p className={`align-right`}>
                        <Link {...locations.REGISTER.link} />
                    </p>
                </GridCell>
            </InputForm>

        </ContentSmall>
    );
};

ResetPasswordView.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    handleResetAsync: PropTypes.func.isRequired,
    setWarnings: PropTypes.func.isRequired,
};

export default ResetPasswordView;
