import PropTypes from "prop-types";
import React from "react";
import {getRoleList} from "../../Components/Authorize/roleConstants";
import {GridCell, gridConstants} from "../../Components/Grid";
import {Button, InputForm, InputFormHeader, SelectField, TextField} from "../../Components/Inputs";
import {createYesNoList} from "../../Components/Inputs/inputUtility";
import {locations} from "../../Utilities/Location";
import {getLinkWithNewLabel} from "../../Utilities/Location/locationUtilities";

const UserSearchFormView = ({
                                handleSetUserSearchCriteria,
                                isEditing,
                                isLoading,
                                userSearchCriteria
                            }) => {
    const handleChangeForm = (event) => {
        let criteria = {...userSearchCriteria};
        criteria[event.target.id] = event.target.value;
        criteria.loadSearchResults = false;

        handleSetUserSearchCriteria(criteria);
    };

    const handleClearClick = (event) => {
        event.preventDefault();
        let criteria = {...userSearchCriteria};
        criteria.ClearUserSearch();
        handleSetUserSearchCriteria(criteria);
    };

    const handleSearchClick = (event) => {
        event.preventDefault();
        let criteria = {...userSearchCriteria};

        criteria.ClearSearch();
        handleSetUserSearchCriteria(criteria);
    };

    return (
        <>
            <InputFormHeader h2={`Search Users`} link1={getLinkWithNewLabel(locations.DASHBOARD.link, "Return to the Dashboard")} />
            <InputForm name={"userSearch"}>
                <TextField
                    label="Name or Email"
                    medium_columns={gridConstants.column.SIX}
                    name="userInfo"
                    onChange={handleChangeForm}
                    value={userSearchCriteria.userInfo}
                />
                <SelectField
                    defaultOptionValue={"-1"}
                    defaultOption={"All"}
                    includeDefaultOption
                    label={"Role"}
                    medium_columns={gridConstants.column.THREE}
                    name={"roleId"}
                    onChange={handleChangeForm}
                    options={getRoleList()}
                    value={userSearchCriteria.roleId}
                />
                <SelectField
                    defaultOptionValue={""}
                    defaultOption={"All"}
                    includeDefaultOption
                    label={"Is Active"}
                    medium_columns={gridConstants.column.THREE}
                    name={"isActive"}
                    onChange={handleChangeForm}
                    options={createYesNoList(false)}
                    value={userSearchCriteria.isActive}
                />
                <GridCell medium_columns={gridConstants.column.SIX}/>
                <GridCell medium_columns={gridConstants.column.THREE}>
                    <Button
                        disabled={isLoading || isEditing}
                        label="Search"
                        name="btnSearchUsers"
                        onClick={handleSearchClick}
                        isPrimary
                    />
                </GridCell>
                <GridCell medium_columns={gridConstants.column.THREE}>
                    <Button
                        disabled={isLoading || isEditing}
                        name="btnClearUserSearch"
                        onClick={handleClearClick}
                        label="Clear"
                    />
                </GridCell>
            </InputForm>
        </>
    );
};

UserSearchFormView.propTypes = {
    handleSetUserSearchCriteria: PropTypes.func.isRequired,
    isEditing: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired,
    userSearchCriteria: PropTypes.object.isRequired
};

export default UserSearchFormView;
