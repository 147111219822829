import {
    apiConstants,
    config,
    mockResolveApiCall,
    requestOptions,
    requestTypes,
    sendRequest,
} from "../../../Utilities/Api";

//************************************************** STANDARD API SERVER CALLS ****************************************

class ServerActivitySettingsApi {
    static getActivitySettings(activityId) {
        return sendRequest(
            requestOptions(`${config.URL}admin/activities/${activityId}`,
                requestTypes.GET
            ),
        );
    }

    static updateActivity(activitySettings) {
        return sendRequest(
            requestOptions(
                `${config.URL}admin/activities/${activitySettings.activityId}`,
                requestTypes.POST,
                JSON.stringify(activitySettings),
                apiConstants.activitySettings.UPDATE_ACTIVITY_SETTINGS
            )
        );
    }

    static addActivity(activitySettings) {
        return sendRequest(
            requestOptions(
                `${config.URL}admin/activities`,
                requestTypes.PUT,
                JSON.stringify(activitySettings),
                apiConstants.activitySettings.ADD_ACTIVITY_SETTINGS
            )
        );
    }
}

//************************************************** MOCK API SERVER CALLS ****************************************

class MockActivitySettingsApi {
    static getActivitySettings() {
        return mockResolveApiCall();
    }

    static updateActivity() {
        return mockResolveApiCall();
    }

    static addActivity() {
        return mockResolveApiCall();
    }
}

const ActivitySettingsApi = config.environment.MODE === config.modes.SERVER ? ServerActivitySettingsApi : MockActivitySettingsApi;
export default ActivitySettingsApi;