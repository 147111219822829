/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from "prop-types";
import React, {useLayoutEffect} from "react";
import {locations} from "../../Utilities/Location";

function SignOutContainer ({redirect, setLoggedInFalse}) {

    useLayoutEffect(() => {
        setLoggedInFalse();
        redirect(locations.SIGN_IN.path);
    }, []);

    return <p>You have been successfully signed out. If you are not redirected you can close this page anytime.</p>;
}

SignOutContainer.propTypes = {
    redirect: PropTypes.func.isRequired,
    setLoggedInFalse: PropTypes.func.isRequired,
};

export default SignOutContainer;