import {searchModel} from "../../../Utilities/Search";

export const activitySearchModel = (activityId, activityInfo, activityStatus) => {
    activityId = activityId || -1;
    return Object.assign({}, searchModel(activityId.toString(), "activityTitle", false, "activityTitle"), {
        activityInfo: activityInfo || "",
        activityStatus: activityStatus || "-1",
        ClearActivitySearch: clearActivitySearch,
    });
};

function clearActivitySearch() {
    this.activityInfo = "";
    this.activityStatus = "-1";
    this.ClearSearch(false);
}