import PropTypes from "prop-types";
import { ButtonIconDelete, ButtonIconLock, ButtonIconRename, ButtonIconReplace } from "../../Components/Inputs";
import { ExternalLink } from "../../Components/Link";
import { MEDIA_ASSETS_ROOT } from "./mediaAssetsConstants";
import { getPathWithSlash } from "./mediaUtilities";

const MediaAssetFileView = ({ 
    mediaAsset,
    isEditing,
    setEditAssetName,
    handleDeleteAsset,
    mediaAssetsPath,
    uploadFile,
    handleOpenModal
 }) => {

    const handleDeleteFileClick = (event) => {
        event.preventDefault();
        handleDeleteAsset(mediaAsset.name);
    };

    const handleRenameFileClick = (event) => {
        event.preventDefault();
        setEditAssetName(mediaAsset.name);
    };

    const handleReplaceFileClick = (event) => {
        event.preventDefault();
        uploadFile(event.target.files, mediaAsset.name);
    };

    const handleLockIconButtonClick = (event) => {
        event.preventDefault(); 
        handleOpenModal(mediaAsset);
    };

    return (
        <tr>
            <td>
                <div className={'asset asset--file'}>
                    <ExternalLink
                        label={mediaAsset.name}
                        url={`${MEDIA_ASSETS_ROOT}${getPathWithSlash(mediaAssetsPath)}${mediaAsset.name}`} />
                </div>
            </td>
            <td>{mediaAsset.dateModifiedOutput}</td>
            {
                !mediaAsset.isUsed &&
                <td className={"no-wrap align-center"}>
                    <ButtonIconRename
                        disabled={isEditing}
                        inputDescription={`for ${mediaAsset.name}`}
                        onClick={(event) => handleRenameFileClick(event)}
                    />
                    <ButtonIconDelete
                        disabled={isEditing}
                        inputDescription={`for ${mediaAsset.name}`}
                        onClick={(event) => handleDeleteFileClick(event)}
                    />
                </td>
            }
            {
                mediaAsset.isUsed &&
                <td className={"no-wrap align-center"}>
                    <ButtonIconLock
                        disabled={isEditing}
                        inputDescription={`for ${mediaAsset.name}`}
                        onClick={(event) => handleLockIconButtonClick(event)}
                    />
                    <ButtonIconReplace
                        disabled={isEditing}
                        inputDescription={`for ${mediaAsset.name}`}
                        onClick={(event) => handleReplaceFileClick(event)}
                        name={mediaAsset.name}
                    />
                </td>
            }
        </tr>
    );
};

MediaAssetFileView.propTypes = {
    mediaAsset: PropTypes.object.isRequired,
    isEditing: PropTypes.bool.isRequired,
    setEditAssetName: PropTypes.func.isRequired,
    handleDeleteAsset: PropTypes.func.isRequired,
    mediaAssetsPath: PropTypes.string.isRequired,
    uploadFile: PropTypes.func.isRequired,
    handleOpenModal: PropTypes.func.isRequired
};

export default MediaAssetFileView;
