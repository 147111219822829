const values = {
    class: {
        GRID_X: `grid-x`,
        CELL: `cell`,
        GRID_MARGIN_Y: `grid-margin-y`,
        GRID_MARGIN_X: `grid-margin-x`,
	    GRID_PADDING_Y: `grid-padding-y`,
	    GRID_PADDING_X: `grid-padding-x`,
	    GRID_FLUSH: `grid--flush`,
	    GRID_REVERSE: `grid--reverse`,
        HIDE_SMALL: `hide-for-small-only`,
    },
    column: {
        ZERO: `0`,
        ONE: `1`,
        TWO: `2`,
        THREE: `3`,
        FOUR: `4`,
        FIVE: `5`,
        SIX: `6`,
        SEVEN: `7`,
        EIGHT: `8`,
        NINE: `9`,
        TEN: `10`,
        ELEVEN: `11`,
        TWELVE: `12`,
        AUTO: `auto`,
        SHRINK: `shrink`,
    },
};
export default values;
