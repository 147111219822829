import {
    config,
    mockResolveApiCall,
    requestOptions,
    requestTypes,
    sendRequest,
} from "../../../Utilities/Api";

//************************************************** STANDARD API SERVER CALLS ****************************************

class ServerActivitySearchApi {
    static getActivities(activitySearchCriteria) {
        return sendRequest(
            requestOptions(`${config.URL}admin/activities/`,
                requestTypes.POST,
                JSON.stringify(activitySearchCriteria),
            ),
        );
    }
}

//************************************************** MOCK API SERVER CALLS ****************************************

class MockActivitySearchApi {
    static getActivities() {
        return mockResolveApiCall();
    }
}

const ActivitySearchApi = config.environment.MODE === config.modes.SERVER ? ServerActivitySearchApi : MockActivitySearchApi;
export default ActivitySearchApi;