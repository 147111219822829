import {
    apiConstants,
    config,
    mockResolveApiCall,
    requestOptions,
    requestTypes,
    sendRequest,
} from "../../Utilities/Api";


//************************************************** STANDARD API SERVER CALLS ****************************************

class ServerAssetApi {

    static getAssets(path) {
        return sendRequest(
            requestOptions(config.URL + `assets/${path}`,
                requestTypes.GET,
                JSON.stringify(path),
            ),
        );
    }

    static saveNewFolder(path, directoryName) {
        return sendRequest(
            requestOptions(`${config.URL}assets/${path}/${directoryName}`,
                requestTypes.POST,
                null,
                apiConstants.asset.ADD_NEW_FOLDER
            ),
        );
    }

    static renameAsset(path, oldName, newName) {
        return sendRequest(
            requestOptions(`${config.URL}assets/${path}/${oldName}/${newName}`,
                requestTypes.PUT,
                null,
                apiConstants.asset.RENAME_ASSET
            ),
        );
    }

    static deleteAsset(path, assetToDelete) {
        return sendRequest(
            requestOptions(`${config.URL}assets/${path}/${assetToDelete}`,
                requestTypes.DELETE,
                null,
                apiConstants.asset.DELETE_ASSET
            ),
        );
    }

    static uploadAssets(path, formData) {
        let request = requestOptions(`${config.URL}assets/${path}`,
            requestTypes.POST,
            formData,
            apiConstants.asset.UPLOAD_ASSETS
        );
        request.processData = false;
        request.enctype = `multipart/form-data`;
        request.contentType = false;

        return sendRequest(request);
    }
}

//************************************************** MOCK API SERVER CALLS ****************************************

class MockAssetApi {
    static getAssets() {
        return mockResolveApiCall();
    }
    static saveNewFolder() {
        return mockResolveApiCall();
    }
    static renameAsset() {
        return mockResolveApiCall();
    }
    static deleteAsset() {
        return mockResolveApiCall();
    }
    static uploadAssets() {
        return mockResolveApiCall();
    }
}

const MediaAssetApi = config.environment.MODE === config.modes.SERVER ? ServerAssetApi : MockAssetApi;
export default MediaAssetApi;