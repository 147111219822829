import {createListFromObject} from "../../Utilities/Types/listUtilities";
import {createObjectFromObjectPropertyNames} from "../../Utilities/Types/objectUtilities";

export const ROLES = {
    Preliminary: 0,
    Author: 1,
    Admin: 2
};

export const ROLE_NAMES = createObjectFromObjectPropertyNames(ROLES);

export function getRoleList() {
    return createListFromObject(ROLES);
}