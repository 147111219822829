import PropTypes from "prop-types";
import React, {createContext, useContext, useEffect, useState} from "react";
import {activitySearchModel} from "../../../Containers/Activity/ActivitySearch/activitySearchFactory";
import userApi from "../../../Containers/userApi";
import {userModel} from "../../../Containers/userFactory";
import {userSearchModel} from "../../../Containers/UserMgmt/userSearchFactory";
import {isValidUser} from "../../../Containers/userUtilities";
import * as storageUtilities from "../../../Utilities/LocalStorage/storageUtilities";
import {isObjectNullOrEmpty} from "../../../Utilities/Types/objectUtilities";
import {useAppContext} from "./AppContext";

export const UserContext = createContext([]);
export const useUserContext = () => useContext(UserContext);

export const UserContextProvider = ({
                                        children,
                                    }) => {

    const [user, setUser] = useState(userModel());
    const isLoggedIn = storageUtilities.isLoggedIn();
    const {handleApiCall} = useAppContext();

    const userId = storageUtilities.getLoggedInUser();
    const searchProps = [userId];

    const [userSearchCriteria, setUserSearchCriteria] = useState(null);
    const handleSetUserSearchCriteria = (criteria) => setUserSearchCriteria(criteria);
    const updateUserSearchCriteria = () => updateSearchCriteria(...searchProps, userSearchCriteria,
        setUserSearchCriteria, userSearchModel);

    const [activitySearchCriteria, setActivitySearchCriteria] = useState(null);
    const handleSetActivitySearchCriteria = (criteria) => setActivitySearchCriteria(criteria);
    const updateActivitySearchCriteria = () => updateSearchCriteria(...searchProps, activitySearchCriteria,
        setActivitySearchCriteria, activitySearchModel);

    const updateSearchCriteria = (userId, searchCriteria, setSearchCriteria, searchModel) => {
        if (!userId) return;

        const searchCriteriaUserId = searchCriteria ? searchCriteria.pageId : userId.toString();

        if ((isObjectNullOrEmpty(searchCriteria) || searchCriteria.pageId !== searchCriteriaUserId)) {
            setSearchCriteria(searchModel(userId));
        }
    }

    const setUserIfValid = (user) => {
        user = userModel(user);
        if (isValidUser(user)) {
            setUser(user);
            return true;
        }

        return false;
    };

    const setLoggedInFalse = () => {
        storageUtilities.desistLogin();
        setUser(userModel());
        return false;
    };

    const setLoggedInTrue = (user) => {
        if (!isValidUser(user))
            return setLoggedInFalse();

        storageUtilities.persistLogin(user.userId, user.accessToken, user.refreshToken, user.isAdmin, user.roleId);

        if(user.notifier)
            user.notifier.notify();

        return true;
    };

    const fetchUser = () => {
        return handleApiCall(userApi.getUser, setUserIfValid, setLoggedInFalse);
    };

    useEffect(() => {
        updateUserSearchCriteria();
        updateActivitySearchCriteria();
    }, [userId]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <UserContext.Provider
            value={{
                user,
                activitySearchCriteria,
                fetchUser,
                handleSetActivitySearchCriteria,
                handleSetUserSearchCriteria,
                isAdmin: storageUtilities.isAdmin(),
                isLoggedIn,
                setLoggedInFalse,
                setLoggedInTrue,
                userSearchCriteria
            }}>
            {children}
        </UserContext.Provider>
    );
};

UserContextProvider.propTypes = {
    children: PropTypes.any.isRequired,
};