import {
    config,
    mockResolveApiCall,
    requestOptions,
    requestTypes,
    sendRequest,
} from "../../Utilities/Api";

//************************************************** STANDARD API SERVER CALLS ****************************************

class ServerSignInApi {
    static attemptLogin(signInModel) {
        return sendRequest(
            requestOptions(config.URL + "Authentication/Login/",
                requestTypes.POST,
                JSON.stringify(signInModel),
            ),
        );
    }
}

//************************************************** MOCK API SERVER CALLS ****************************************

class MockSignInApi {
    static attemptLogin() {
        return mockResolveApiCall();
    }
}

const SignInApi = config.environment.MODE === config.modes.SERVER ? ServerSignInApi : MockSignInApi;
export default SignInApi;