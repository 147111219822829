import PropTypes from "prop-types";
import React from "react";
import {Link} from "react-router-dom";
import {Layout} from "../../Components/Layout";
import button_back from "../../images/button_back.png";
import {GradeLevelsFilter} from "../../SiteConfig/gradeLevelConstants";
import {TopicsFilter} from "../../SiteConfig/topicConstants";
import {useDocumentTitle} from "../../Utilities/landmarkUtilities";
import {locations} from "../../Utilities/Location";
import {getActivityTitle, getActivityType} from "../activityUtilities";


const ActivitySearchResultsContainer = ({
                                            activity,
                                            activityDetails,
                                            redirect
                                        }) => {
    const activityType = getActivityType(activity);
    const activityTitle = getActivityTitle(activity);

    if (!activityType)
        redirect(locations.LANDING.path);

    useDocumentTitle(`Activities by ${activityTitle} - ${activityType[activity]}`);

    if(activityDetails.length === 0)
        return null;

    const filteredActivityDetails = activityDetails.filter(f => f.topic === TopicsFilter[activity] || f.grade === GradeLevelsFilter[activity]);

    return (
        <Layout>
            <div className="by_grade_level">
                <div className="by_grade_level__header">
                    <div className="by_grade_level__back">
                        <Link to={locations.LANDING.path}><img src={button_back} alt="Back"/></Link>
                    </div>
                    <h2>Activities by {activityTitle}</h2>
                </div>
                {
                    filteredActivityDetails.length > 0 &&
                    <ul className="by_grade_level__activities -twoUp">
                        {
                            filteredActivityDetails.map((detail, index) => {
                                return (
                                    <li key={index}>
                                        <Link to={locations.ACTIVITY.getPath(activity, detail.id)}>
                                            <div className="activities__header">
                                                {detail.title}
                                            </div>
                                            <div className="activities__image">
                                                <img src={detail.thumbnailUrl} alt={detail.title}/>
                                            </div>
                                        </Link>
                                    </li>
                                );
                            })
                        }
                    </ul>
                }
            </div>
            {
                filteredActivityDetails.length === 0 &&
                <div className="by_topics">
                    <h3 className={'align-center'}>No activities available for that type.</h3>
                </div>
            }
        </Layout>
    );
};

ActivitySearchResultsContainer.propTypes = {
    activity: PropTypes.string.isRequired,
    activityDetails: PropTypes.array.isRequired,
    redirect: PropTypes.func.isRequired
}

export default ActivitySearchResultsContainer;
