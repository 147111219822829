import {catchError} from "../../../Utilities/Api";
import {DefaultError} from "./Notification/DefaultError";
import {DefaultNotification} from "./Notification/DefaultNotification";
import {MultiErrorMessageNotification} from "./Notification/MultiErrorMessageNotification";
import {NoNotification} from "./Notification/NoNotification";
import {NotificationTypes} from "./Notification/NotificationTypes";
import {ToastrAdapter} from "./Toastr/ToastrAdapter";

export class NotificationFactory {
	constructor() {
		this._notificationAdapter = new ToastrAdapter();
	}

	clear(clearErrorWarningToastsOnly = false) {
		return this._notificationAdapter.clear(clearErrorWarningToastsOnly);
	}

	createNone() {
		return new NoNotification();
	}

	createSuccess(message) {
		return new DefaultNotification(this._notificationAdapter, NotificationTypes.SUCCESS, message);
	}

	createWarning(message) {
		return new DefaultNotification(this._notificationAdapter, NotificationTypes.WARNING, message);
	}

	createError(message) {
		return new DefaultNotification(this._notificationAdapter, NotificationTypes.ERROR, message);
	}

	createErrorsFromXhrObject(xhrObject) {
		if (xhrObject.status === 403)
			return new DefaultNotification(
				this._notificationAdapter,
				NotificationTypes.ERROR,
				"Please contact support to verify your access to this section.",
			);

		if (xhrObject.responseText === "") return new NoNotification();

		let errorResponseObject;

		try {
			errorResponseObject = JSON.parse(xhrObject.responseText);
		} catch (e) {
			catchError("The server did not return a valid error object.");
			return new DefaultError(this._notificationAdapter);
		}

		if (!errorResponseObject || !errorResponseObject.errors) return new DefaultError(this._notificationAdapter);

		let notification;
		let totalErrors = errorResponseObject.totalErrors;

		if (totalErrors === 1)
			notification = new DefaultNotification(
				this._notificationAdapter,
				NotificationTypes.ERROR,
				errorResponseObject.errors[0].message,
			);
		else if (totalErrors > 1)
			notification = new MultiErrorMessageNotification(this._notificationAdapter, errorResponseObject.errors);
		else notification = new NoNotification();

		return notification;
	}

	createInfo(message) {
		return new DefaultNotification(this._notificationAdapter, NotificationTypes.INFO, message);
	}
}
