import React from "react";
import PropTypes from 'prop-types';

export const Main = ({
                         children,
                         id = "main"
                     }) => {


    return (
        <main id={id} tabIndex="-1" className={id}>
            <div id={"toast-container"} aria-live={`assertive`}/>
            {children}
        </main>
    );
};

Main.propTypes = {
    children: PropTypes.any,
    id: PropTypes.string
};