import { render } from "@testing-library/react";
import React from "react";

export async function renderContainer(Component, props = {}) {
    return render(<Component {...props} />);
}

export const buildTarget = (targetName, targetValue) => {
    return {
        target: { name: targetName, value: targetValue },
    };
};


export function emptyFunction() {
}

export function handleApiCall(apiCall, thenCallback = emptyFunction, catchCallback = emptyFunction) {
    apiCall()
        .then((result) => {
            thenCallback(result);
        })
        .catch((error) => {
            catchCallback(error);
        });
}
export function redirect() {}
export function fetchUser() {}
export function setWarnings() {}
export function setLoggedInTrue() {}
export function setLoggedInFalse() {}
export const history = {
    goBack: emptyFunction,
};
