import {createListFromObject} from "../Utilities/Types/listUtilities";

export const TopicsTitle = "Topics";

export const Topics = {
    Fluency: "Fluency",
    Vocabulary: "Vocabulary",
    Phonics: "Phonics",
    PhonologicalAwareness: "Phonological Awareness",
    Comprehension: "Comprehension",
    AlphabetKnowledge: "Alphabet Knowledge"
};

export const TopicsList = createListFromObject(Topics);

export const TopicsFilter = {
    Fluency: "Fluency",
    Vocabulary: "Vocabulary",
    Phonics: "Advanced Phonics",
    PhonologicalAwareness: "Phonological Awareness",
    Comprehension: "Comprehension",
    AlphabetKnowledge: "Alphabet Knowledge"
};