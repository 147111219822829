import React from "react";
import {Allow, policyEvents} from "../../Components/Authorize";
import {PageTitle} from "../../Components/Landmarks";
import {ContentSmall} from "../../Components/Layout";
import {Link} from "../../Components/Link";
import {locations} from "../../Utilities/Location";

const DashboardContainer = () => {

    return (
        <ContentSmall>
            <PageTitle h1={locations.DASHBOARD.label} />
            <ul>
                <Allow policyEvent={policyEvents.MANAGE_USERS}>
                    <li><Link to={locations.MANAGE_USERS.path} label={locations.MANAGE_USERS.label} /> - update user information</li>
                </Allow>
                <li><Link to={locations.MEDIA_ASSETS_REDIRECT.getPath("")} label={locations.MEDIA_ASSETS_REDIRECT.label} /> - add or update media assets used in the activities</li>
                <li><Link to={locations.MANAGE_ACTIVITIES.path} label={locations.MANAGE_ACTIVITIES.label} /> - add or update activities</li>
                <li><Link to={locations.SIGN_OUT.path} label={locations.SIGN_OUT.label} /> - sign out of the system</li>
            </ul>
        </ContentSmall>
    );
};

export default DashboardContainer;
