import {ROLES} from "../Components/Authorize/roleConstants";

export const userModel = ({
                              accessToken,
                              email,
                              firstName,
                              isActive,
                              lastName,
                              refreshToken,
                              userId,
                              roleId
                          } = {}) => ({
    accessToken,
    email,
    firstName: firstName || "",
    isActive,
    lastName: lastName || "",
    refreshToken,
    userId: userId || 0,
    roleId: roleId ?? ROLES.Preliminary
});
