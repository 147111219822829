function getFilenameFromHeader(xhrObject) {
	const contentDispositionHeader = xhrObject.getResponseHeader("Content-Disposition");

	if (!contentDispositionHeader)
		throw new Error("Content-Disposition header not found. Check CORS policy to ensure this header is exposed.");

	let filename = contentDispositionHeader.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/)[1];

	if (!filename) throw new Error("Filename didn't exist in the header.");

	filename = filename.replace(/"/g, "");

	return filename;
}
const downloadUtilityProps = {
	getFilenameFromHeader,
};
export default downloadUtilityProps;
