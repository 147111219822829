import { locations } from "../../Utilities/Location";
import { PageTitle } from "../../Components/Landmarks";
import PropTypes from "prop-types";
import MediaAssetApi from "./mediaAssetApi";
import { useEffect, useState } from "react";
import MediaAssetsView from "./MediaAssetsView";
import MediaAssetsDirectoryView from "./MediaAssetsDirectoryView";
import { isNullOrUndefined } from "../../Utilities/commonUtilities";
import { getPathWithSlash } from "./mediaUtilities";

const MediaAssetsContainer = ({
    handleApiCall,
    mediaAssetsPath,
    redirect,
    setWarnings
}) => {

    const [assets, setAssets] = useState(null);
    // editAssetName will be empty, when creating new folder
    // editAssetName will have an asset name, while doing rename
    // null otherwise
    const [editAssetName, setEditAssetName] = useState(null);

    const isEditing = !isNullOrUndefined(editAssetName);

    const getAssets = async (mediaAssetsPath) => {
        await handleApiCall(() => MediaAssetApi.getAssets(encodeURIComponent(`/${getPathWithSlash(mediaAssetsPath)}`)), setAssets);
    };

    useEffect(() => {
        getAssets(mediaAssetsPath);
    }, [mediaAssetsPath]);// eslint-disable-line react-hooks/exhaustive-deps


    const navigateToAsset = (mediaAssetName) => {
        redirect(locations.MEDIA_ASSETS_REDIRECT.getPath(`${getPathWithSlash(mediaAssetsPath)}${mediaAssetName}`));
    };


    const handleSaveNewFolder = async (directoryName) => {
        await handleApiCall(() => MediaAssetApi.saveNewFolder(
            encodeURIComponent(`/${getPathWithSlash(mediaAssetsPath)}`),
            encodeURIComponent(directoryName)), reloadMediaAssets);
    }

    const handleRenameAsset = async (oldName, newName) => {
        oldName !== newName ?
            await handleApiCall(() => MediaAssetApi.renameAsset(
                encodeURIComponent(`/${getPathWithSlash(mediaAssetsPath)}`),
                encodeURIComponent(oldName),
                encodeURIComponent(newName)), reloadMediaAssets)
            : setEditAssetName(null);
    }


    const handleDeleteAsset = async (assetToDelete) => {
        if (confirm("Are you sure you want to delete this asset?\n\nPress \"Ok\" to continue or \"Cancel\" to return to the page.")) /* eslint-disable-line */ {
            await handleApiCall(() => MediaAssetApi.deleteAsset(
                encodeURIComponent(`/${getPathWithSlash(mediaAssetsPath)}`),
                encodeURIComponent(assetToDelete)), setAssets);
        }
    }

    const handleUploadAssets = async (formData) => {
        await handleApiCall(() => MediaAssetApi.uploadAssets(
            encodeURIComponent(`/${getPathWithSlash(mediaAssetsPath)}`),
            formData), reloadMediaAssets, reloadMediaAssetsWithIssues);
    }

    const reloadMediaAssets = (results) => {
        setAssets(results);
        setEditAssetName(null);
    }

    const reloadMediaAssetsWithIssues = () => {
        getAssets(mediaAssetsPath);
        setEditAssetName(null);
    }

    return (
        <>
            <PageTitle h1={locations.MEDIA_ASSETS_REDIRECT.label} isCentered />
            <p>Only files contained in the "common" or activity ID directories will be available to be included in activity.  In other words, for an activity that has an ID of "PA11", only upload files in a "PA11" or "common" directory.</p>
            <p>When an asset is in use and locked, it will no longer be able to be renamed or deleted but can be replaced using the same name.  Click on the &quot;lock icon&quot; to see more details on which activities are using the asset.</p>
            <MediaAssetsDirectoryView
                mediaAssetsPath={mediaAssetsPath}
                isEditing={isEditing} />
            <MediaAssetsView
                assets={assets}
                navigateToAsset={navigateToAsset}
                setAssets={setAssets}
                isEditing={isEditing}
                setEditAssetName={setEditAssetName}
                editAssetName={editAssetName}
                handleSaveNewFolder={handleSaveNewFolder}
                setWarnings={setWarnings}
                handleDeleteAsset={handleDeleteAsset}
                handleRenameAsset={handleRenameAsset}
                handleUploadAssets={handleUploadAssets}
                mediaAssetsPath={mediaAssetsPath}
            />
        </>
    );

}

MediaAssetsContainer.propTypes = {
    handleApiCall: PropTypes.func.isRequired,
    mediaAssetsPath: PropTypes.string.isRequired,
    redirect: PropTypes.func.isRequired,
    setWarnings: PropTypes.func.isRequired
};

export default MediaAssetsContainer;