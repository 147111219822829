import * as policyEvents from "./policyEventsConstants";
import {ROLES} from "./roleConstants";

export class DefaultPolicy {
    constructor(roleId) {
        this._policy = {};
        this._roleId = roleId;

        this.initializePolicy();
    }

    permit(policyEvent) {
        if (!policyEvent)
            return false;

        if (this._policy[policyEvent])
            return this._policy[policyEvent](this._roleId);

        return false;
    }

    initializePolicy() {
        this._policy[policyEvents.MANAGE_USERS] = function (roleId) {
            return roleId === ROLES.Admin;
        };
        this._policy[policyEvents.VIEW] = function (roleId) {
            return roleId === ROLES.Admin || roleId === ROLES.Author;
        };
        this._policy[policyEvents.MANAGE] = function (roleId) {
            return roleId === ROLES.Admin || roleId === ROLES.Author;
        };
        this._policy[policyEvents.DELETE] = function (roleId) {
            return roleId === ROLES.Admin || roleId === ROLES.Author;
        };
    }
}