import PropTypes from "prop-types";
import React from "react";
import GridTable from "../../../Components/Grid/GridTable";
import ResultsPager from "../../../Components/Grid/ResultsPager";
import ActivitySearchResultsRow from "./ActivitySearchResultsRow";

const ActivitySearchResultsView = ({
                                       activities,
                                       activitySearchCriteria,
                                       handleSetActivitySearchCriteria,
                                       isLoading,
                                       redirect,
                                       totalRecords
                                   }) => {
    const handleFirstClick = (event) => {
        event.preventDefault();
        let criteria = {...activitySearchCriteria};
        criteria.First();

        handleSetActivitySearchCriteria(criteria);
    };

    const handlePreviousClick = (event) => {
        event.preventDefault();
        let criteria = {...activitySearchCriteria};
        criteria.Previous();

        handleSetActivitySearchCriteria(criteria);
    };

    const handleNextClick = (event) => {
        event.preventDefault();
        let criteria = {...activitySearchCriteria};
        criteria.Next();

        handleSetActivitySearchCriteria(criteria);
    };

    const handleLastClick = (event) => {
        event.preventDefault();
        let criteria = {...activitySearchCriteria};
        criteria.Last(totalRecords);

        handleSetActivitySearchCriteria(criteria);
    };

    const handleOrderClick = (event) => {
        event.preventDefault();
        let criteria = {...activitySearchCriteria};
        criteria.UpdateSorting(event.target.dataset.id);
        handleSetActivitySearchCriteria(criteria);
    };

    if (!activities) return null;

    if (activities.length === 0) return <p>The activity search yielded no results.</p>;

    const pager = (isSecondaryPager) =>
        <ResultsPager
            handleFirstClick={handleFirstClick}
            handlePreviousClick={handlePreviousClick}
            handleNextClick={handleNextClick}
            handleLastClick={handleLastClick}
            isSecondaryPager={isSecondaryPager}
            totalRecords={totalRecords}
            recordsPerPage={activitySearchCriteria.recordsPerPage}
            currentPage={activitySearchCriteria.page}
            disabled={isLoading}
        />;

    return (
        <>
            {pager()}
            <GridTable>
                <thead>
                <tr>
                    <th scope={"col"} onClick={handleOrderClick} className="is-clickable" data-id="activityManifestId">
                        ID
                    </th>
                    <th scope={"col"} onClick={handleOrderClick} className="is-clickable" data-id="activityTitle">
                        Title
                    </th>
                    <th scope={"col"} onClick={handleOrderClick} className="is-clickable"
                        data-id="activityStatusOutput">
                        Status
                    </th>
                    <th scope={"col"} onClick={handleOrderClick} className="is-clickable" data-id="lastModifiedDate">
                        Last Modified
                    </th>
                    <th scope={"col"} className={"align-center"}>
                        Actions
                    </th>
                </tr>
                </thead>
                <tbody>
                {activities.map((activity, index) => {
                    return <ActivitySearchResultsRow key={index}
                                                     activity={activity}
                                                     redirect={redirect}
                    />;
                })}
                </tbody>
            </GridTable>

            {pager(true)}
        </>
    );
};

ActivitySearchResultsView.propTypes = {
    activities: PropTypes.arrayOf(PropTypes.object),
    activitySearchCriteria: PropTypes.object.isRequired,
    handleSetActivitySearchCriteria: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    redirect: PropTypes.func.isRequired,
    totalRecords: PropTypes.number.isRequired
};

export default ActivitySearchResultsView;
