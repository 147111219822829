import PropTypes from "prop-types";
import React from "react";
import {HtmlDisplay} from "../Display";
import {GridCell} from "../Grid";
import {HelpText} from "./HelpText";
import {Notification} from "./Notification";

const InputLayout = ({
                         children,
                         className,
                         disabled,
                         helpText,
                         label,
                         medium_columns,
                         name,
                         error,
                         responseFeedback,
                         showOnlyForSr = false
                     }) => {
    const showForSr = showOnlyForSr ? "show-for-sr" : "";
    return (
        <GridCell medium_columns={medium_columns} className={className}>
            {
                label &&
                <label
                    aria-disabled={disabled}
                    htmlFor={name}
                    className={showForSr}
                >
                    <HtmlDisplay html={label} isInline/>
                </label>
            }
            {children}
            <HelpText name={name} helpText={helpText} responseFeedback={responseFeedback}/>
            <Notification error={error} name={name} label={label}/>
        </GridCell>
    );
};

export const inputLayoutProps = {
    className: PropTypes.string,
    error: PropTypes.string,
    helpText: PropTypes.string,
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
};

InputLayout.propTypes = {
    ...inputLayoutProps,
    children: PropTypes.any.isRequired,
    disabled: PropTypes.bool,
    medium_columns: PropTypes.string,
    responseFeedback: PropTypes.string,
    showOnlyForSr: PropTypes.bool
};

export {InputLayout};
