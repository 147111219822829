import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import ActivityWebGlView from "../../../Components/Activity/ActivityWebGlView";
import {GridCell} from "../../../Components/Grid";
import {PageTitle} from "../../../Components/Landmarks";
import {Layout} from "../../../Components/Layout";
import Link from "../../../Components/Link";
import {locations} from "../../../Utilities/Location";
import activitySettingsApi from "../ActivitySettings/activitySettingsApi";
import ActivityDetailsApi from "./activityDetailsApi";
import {createActivityDetails} from "./activityDetailsFactory";

const ActivityDetailsContainer = ({
                                      activityId,
                                      handleApiCall
                           }) => {
    const [activitySettings, setActivitySettings] = useState(null);
    const [activityDetails, setActivityDetails] = useState(null);

    const loadActivityInformation = () => {
        handleApiCall(() => activitySettingsApi.getActivitySettings(activityId), setActivitySettings);
        handleApiCall(() => ActivityDetailsApi.getActivityDetails(activityId), setActivityDetails)
    };

    window.GetActivityDetails = () => {
        console.log("Get Activity Details", activityDetails)
        return activityDetails;
    };

    window.SaveActivityDetails = (manifestJson, activityJson, activityTasks) => {
        const activityDetails = createActivityDetails(activityId, manifestJson, activityJson, activityTasks);
        console.log("save activity details", manifestJson, activityJson, activityTasks, activityDetails)
        handleApiCall(() => ActivityDetailsApi.updateActivityDetails(activityId, activityDetails));
    };

    useEffect(() => {
        loadActivityInformation();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    if (!activitySettings || !activityDetails)
        return null;

    return (
        <Layout>
            <PageTitle h1={`${locations.EDIT_ACTIVITY.label} - ${activitySettings.activityTitle}`} isCentered />

            <GridCell className={`h2 align-right padding--bottom`}>
                <Link to={locations.PREVIEW_ACTIVITY.getPath(activitySettings.activityId)} label={`Preview Activity`} />
                {` | `}
                <Link to={locations.MANAGE_ACTIVITIES.path} label={`Return to ${locations.MANAGE_ACTIVITIES.label}`}/>
            </GridCell>

            <ActivityWebGlView
                activityDetail={activitySettings}
                includeStopAllAudioOnUnload={false}
                scriptId={`activityAuthoringScript`}
                webGlName={`ActivityAuthoringTool`}
            />

        </Layout>
    );
};

ActivityDetailsContainer.propTypes = {
    activityId: PropTypes.string.isRequired,
    handleApiCall: PropTypes.func.isRequired
}

export default ActivityDetailsContainer;
