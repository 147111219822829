import {
    config,
    mockResolveApiCall,
    requestOptions,
    requestTypes,
    sendRequest,
} from "../../Utilities/Api";

//************************************************** STANDARD API SERVER CALLS ****************************************

class ServerRegisterApi {
    static attemptRegister(registerModel) {
        return sendRequest(
            requestOptions(config.URL + "Registration/",
                requestTypes.POST,
                JSON.stringify(registerModel),
            ),
        );
    }
}

//************************************************** MOCK API SERVER CALLS ****************************************

class MockRegisterApi {
    static attemptRegister() {
        return mockResolveApiCall();
    }
}

const RegisterApi = config.environment.MODE === config.modes.SERVER ? ServerRegisterApi : MockRegisterApi;
export default RegisterApi;