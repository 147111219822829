import {createListFromObject} from "../../Utilities/Types/listUtilities";
import {createObjectFromObjectPropertyNames} from "../../Utilities/Types/objectUtilities";

export const ASSET_TYPE = {
    Folder: 1,
    File: 2,
};

export const ASSET_TYPE_NAMES = createObjectFromObjectPropertyNames(ASSET_TYPE);

export function getAssetTypeList() {
    return createListFromObject(ASSET_TYPE);
}