import PropTypes from "prop-types";
import React, {useEffect} from "react";
import {useNavigate, useLocation} from "react-router-dom";
import {Authorize} from "../../Components/Authorize";
import {Loading} from "../../Components/Loading";
import {NotifyUser} from "../../Components/Notifications";
import {ContextPropsConsumer} from "../../Components/StateManager";
import {contexts, useAppContext} from "../../Components/StateManager/Context";
import {scrollToTop} from "../../Utilities/commonUtilities";

export const PageSetup = ({
                              component: Component,
                              path,
                              template: Template,
                          }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const pathUrl = location.pathname;
    const redirect = (route) => navigate(route, { replace: false });
    const handleReturnRedirect = () => navigate(-1);

    const {handleApiCall} = useAppContext();

    useEffect(scrollToTop, [location.pathname]);

    return (
        <Template
            handleApiCall={handleApiCall}
            path={path}
            redirect={redirect}
        >
            <ContextPropsConsumer contexts={contexts}
                                  props={{navigate, path, pathUrl, redirect, handleReturnRedirect}}>
                {Loading}
                {NotifyUser}
                {Authorize}
                {Component}
            </ContextPropsConsumer>
        </Template>
    );
};

PageSetup.propTypes = {
    component: PropTypes.func,
    path: PropTypes.string,
    template: PropTypes.func,
};