import React from "react";
import {ContextPropsProvider} from "./Components/StateManager";
import {providers} from "./Components/StateManager/Context";
import {Router} from "./SiteConfig/Routes/Router";
import "./sass/main.scss";

function App() {
    return (
        <ContextPropsProvider providers={providers}>
            <Router/>
        </ContextPropsProvider>
    );
}

export default App;
