import { createArrayFromObject } from "../../../../Utilities/Types/arrayUtilities";

export const buttonIconClassPrefix = "buttonIcon--";
export const buttonLabelClassPrefix = "buttonLabel--";
export const buttonIconType = {
    ADD: "add",
    ADD_OPTION: "addOption",
    CANCEL: "cancel",
    CERTIFICATE: "certificate",
    COLLAPSE: "collapse",
    COLLAPSE_ALL: "collapseAll",
    COPY_COMPONENT: "copyComponent",
    CLEAR: "clear",
    CONTENT: "content",
    DELETE: "delete",
    DELETE_COMPONENT: "deleteComponent",
    DOWNLOAD: "download",
    EDIT: "edit",
    DONE_EDITING: "doneEditing",
    EXIT: "exit",
    EXPAND: "expand",
    EXPAND_ALL: "expandAll",
    ENROLLMENTS: "enrollments",
    FILE: "file",
    FOLDER: "folder",
    LOCK: "lock",
    IMPERSONATE: "impersonate",
    MODULES: "modules",
    ORDER: "order",
    PAGES: "pages",
    PROFILE: "profile",
    REMOVE: "remove",
    RENAME: "rename",
    REPLACE: "replace",
    RESET_PASSWORD: "resetPassword",
    SAVE: "save",
    SECTIONS: "sections",
    SETTINGS: "settings",
    UPLOAD: "upload",
}

export const buttonIconTypeList = createArrayFromObject(buttonIconType);
