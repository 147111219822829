import {isNullOrUndefined} from "../../../Utilities/commonUtilities";
import {ACTIVITY_STATUS} from "../../activityConstants";

export const createActivitySettings = ({
                                           activityId,
                                           activityManifestId,
                                           activityStatus = 1,
                                           activityTitle
                                       } = {}) =>
    ({
        activityId: activityId || 0,
        activityIdToDuplicate: null,
        activityManifestId: activityManifestId || "",
        activityStatus: isNullOrUndefined(activityStatus) ? ACTIVITY_STATUS.Draft : activityStatus,
        activityTitle: activityTitle || ""
    });
