import {createListFromObject} from "../Utilities/Types/listUtilities";

export const GradeLevelsTitle = "Grade Levels";

export const GradeLevels = {
    PreK: "Pre-K",
    KindergartenFirstGrade: "K - 1st Grade",
    SecondThirdGrade: "2nd - 3rd Grade",
    FourthFifthGrade: "4th - 5th Grade"
};

export const GradeLevelsList = createListFromObject(GradeLevels);

export const GradeLevelsFilter = {
    PreK: "PreK",
    KindergartenFirstGrade: "K-1",
    SecondThirdGrade: "2-3",
    FourthFifthGrade: "4-5"
};