import {isNullOrUndefined} from "../../../Utilities/commonUtilities";

export const createActivityDetails = (activityId, manifestJson, activityJson, activityTasks) =>
    ({
        activityId,
        activityJson,
        activityTasks: isNullOrUndefined(activityTasks) ? [] : activityTasks.map((task) => createActivityTasks(task)),
        manifestJson
    });


export const createActivityTasks = ({
                                          taskManifestId,
                                          taskName,
                                          taskContent
                                      } = {}) =>
    ({
        taskManifestId,
        taskName,
        taskContent
    });
