import {
    apiConstants,
    config,
    mockResolveApiCall,
    requestOptions,
    requestTypes,
    sendRequest,
} from "../../Utilities/Api";


//************************************************** STANDARD API SERVER CALLS ****************************************

class ServerPasswordApi {
    static forgotPassword(email) {
        return sendRequest(
            requestOptions(`${config.URL}password/forgotPassword/`,
                requestTypes.POST,
                JSON.stringify(email),
                apiConstants.user.FORGOT_PASSWORD,
            ),
        );
    }
    static resetPassword(resetPasswordViewModel) {
        return sendRequest(
            requestOptions(`${config.URL}password/resetPassword/`,
                requestTypes.POST,
                JSON.stringify(resetPasswordViewModel),
                apiConstants.user.RESET_PASSWORD,
            ),
        );
    }
}

//************************************************** MOCK API SERVER CALLS ****************************************

class MockPasswordApi {
    static forgotPassword() {
        return mockResolveApiCall(true, apiConstants.user.FORGOT_PASSWORD);
    }
    static resetPassword() {
        return mockResolveApiCall(true, apiConstants.user.RESET_PASSWORD);
    }
}

const PasswordApi = config.environment.MODE === config.modes.SERVER ? ServerPasswordApi : MockPasswordApi;
export default PasswordApi;