import PropTypes from "prop-types";
import React from "react";
import {ButtonIconEdit, ButtonIconSettings} from "../../../Components/Inputs";
import {locations} from "../../../Utilities/Location";

const ActivitySearchResultsRow = ({ activity, redirect }) => {
    const handleEditActivityClick = (event, activityId) => {
        event.preventDefault();
        redirect(locations.EDIT_ACTIVITY.getPath(activityId));
    };

    const handleEditActivitySettingsClick = (event, activityId) => {
        event.preventDefault();
        redirect(locations.EDIT_ACTIVITY_SETTINGS.getPath(activityId));
    };

    return (
        <tr>
            <td>{activity.activityManifestId}</td>
            <td>{activity.activityTitle}</td>
            <td>{activity.activityStatusOutput}</td>
            <td>{activity.lastModifiedOutput}</td>
            <td className={"no-wrap align-center"}>
                <ButtonIconSettings
                    inputDescription={`for ${activity.activityTitle} (${activity.activityManifestId})`}
                    onClick={(event) => handleEditActivitySettingsClick(event, activity.activityId)}
                />
                <ButtonIconEdit
                    inputDescription={`for ${activity.activityTitle} (${activity.activityManifestId})`}
                    onClick={(event) => handleEditActivityClick(event, activity.activityId)}
                />
            </td>
        </tr>
    );
};

ActivitySearchResultsRow.propTypes = {
    activity: PropTypes.object.isRequired,
    redirect: PropTypes.func.isRequired
};

export default ActivitySearchResultsRow;
