import { createArrayFromObject } from "../Types/arrayUtilities";
import { createLocation } from "./locationUtilities";

export const params = {
    activity: ":activity",
    activityId: ":activityId",
    resetPasswordGuid: ":resetPasswordGuid",
    mediaAssetsPath: ":mediaAssetsPath"
};

export const locationBehaviors = {
    doNotRedirectBack: "doNotRedirectBack",
    redirectToDashboard: "redirectToDashboardIfAuthenticated",
    requiresAdminAuthentication: "requiresAdminAuthentication",
    requiresAuthentication: "requiresAuthentication"
};

export const locations = {
    LANDING: createLocation(() => `/`, `Home`, []),
    ACTIVITY_SEARCH_RESULTS: createLocation((activity = params.activity) => `/activities/${activity}`, `Activities`, []),
    ACTIVITY: createLocation((activity = params.activity, activityId = params.activityId) => `/activities/${activity}/${activityId}`, `Activity`, []),
    PREVIEW_ACTIVITY: createLocation((activityId = params.activityId) => `/activities/${activityId}/preview`, `Preview Activity`, [locationBehaviors.requiresAuthentication]),

    DASHBOARD: createLocation(() => `/dashboard`, `My Dashboard`, [locationBehaviors.requiresAuthentication]),
    FORGOT_PASSWORD: createLocation(() => `/forgot-password`, `Forgot password?`, [locationBehaviors.redirectToDashboard]),
    REGISTER: createLocation(() => `/register`, `Register`),
    SUCCESSFUL_REGISTER: createLocation(() => `/successfulRegister`, `Registered Successfully`),
    SIGN_IN: createLocation(() => `/sign-in`, `Sign in`, [locationBehaviors.redirectToDashboard]),
    SIGN_OUT: createLocation(() => `/goodbye`, `Sign out`, [locationBehaviors.requiresAuthentication, locationBehaviors.doNotRedirectBack]),
    RESET_PASSWORD: createLocation((resetPasswordGuid = params.resetPasswordGuid) => `/reset-password/${resetPasswordGuid}`, `Reset Password?`),
    
    MANAGE_USERS: createLocation(() => `/users`, `Manage Users`, [locationBehaviors.requiresAuthentication, locationBehaviors.requiresAdminAuthentication]),
    MEDIA_ASSETS_REDIRECT: createLocation((mediaAssetsPath = params.mediaAssetsPath) => `/media-assets/${mediaAssetsPath}`, `Media Assets`, [locationBehaviors.requiresAuthentication]),
    MEDIA_ASSETS: createLocation(() => `/media-assets/*`, `Media Assets`, [locationBehaviors.requiresAuthentication]),

    MANAGE_ACTIVITIES: createLocation(() => `/admin/activities`, `Manage Activities`, [locationBehaviors.requiresAuthentication]),
    ADD_ACTIVITY_SETTINGS: createLocation(() => `/admin/activities/settings`, `Add Activity`, [locationBehaviors.requiresAuthentication]),
    EDIT_ACTIVITY_SETTINGS: createLocation((activityId = params.activityId) => `/admin/activities/${activityId}/settings`, `Activity Settings`, [locationBehaviors.requiresAuthentication]),
    EDIT_ACTIVITY: createLocation((activityId = params.activityId) => `/admin/activities/${activityId}`, `Activity`, [locationBehaviors.requiresAuthentication]),

    NOT_AUTHORIZED: createLocation(() => `/notAuthorized`, `Not Authorized`, [locationBehaviors.requiresAuthentication, locationBehaviors.doNotRedirectBack]),
    NOT_FOUND: createLocation(() => `/404`, `Not Found`, [locationBehaviors.doNotRedirectBack]),
};

export const allLocations = createArrayFromObject(locations);

