import PropTypes from "prop-types";
import React from "react";
import { locations } from "../../Utilities/Location";
import passwordApi from "./passwordApi";
import ResetPasswordView from "./ResetPasswordView";

const ResetPasswordContainer = ({
                                    handleApiCall,
                                    isLoading,
                                    redirect,
                                    resetPasswordGuid,
                                    setWarnings,
                                }) => {

    const redirectOnSubmit = () => {
        redirect(locations.SIGN_IN.path);
    };

    const handleResetAsync = async (password) => {
        return handleApiCall(() => passwordApi.resetPassword({ resetPasswordGuid, password },
           ), redirectOnSubmit);
    };

    return <ResetPasswordView handleResetAsync={handleResetAsync} isLoading={isLoading} setWarnings={setWarnings}/>;
};

ResetPasswordContainer.propTypes = {
    handleApiCall: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    redirect: PropTypes.func.isRequired,
    resetPasswordGuid: PropTypes.string.isRequired,
    setWarnings: PropTypes.func.isRequired,
    siteDetails: PropTypes.object,
};

export default ResetPasswordContainer;