import $ from "jquery";
import { isObjectNullOrEmpty } from "../../Types/objectUtilities";
import { apiConstants } from "../apiConstants";
import { requestTypes } from "./requestTypes";

let processingFirst401Refresh = false;

const ajaxRequestWithRetry = (localStorage, notificationFactory, maxRetryCount, tokenUrl, retryTimeout = 150) => {

    function retryRequest(retryCount, originalHttpRequestOptions) {
        if (retryCount < maxRetryCount) {
            // If first refresh is still processing, retry again in
            // which by that time the refresh token should be retrieved
            setTimeout(function() {
                retryCount++;
                if (processingFirst401Refresh) {
                    retryRequest(retryCount, originalHttpRequestOptions);
                }
                else {
                    sendRequest(originalHttpRequestOptions);
                }
            }, retryTimeout);
        }
    }

    function configure401Retry(ajaxRequestObject) {
        // Capture the original request to revert to this state later
        let originalRequestObject = {};
        Object.assign(originalRequestObject, ajaxRequestObject);

        ajaxRequestObject.error = function(response) {
            if (response.status === 401) {
                // This flag allows 1 refresh request at a time
                // resolving thread safety exceptions when simultaneous Requests
                // are sent to the api
                if (!processingFirst401Refresh) {
                    processingFirst401Refresh = true;
                    sendRequest({
                        url: tokenUrl,
                        data: {
                            refreshToken: localStorage.getRefreshToken(),
                        },

                        contentType: apiConstants.contentType.JSON,
                        type: "GET",
                        success: function(data) {
                            localStorage.saveJwtToken(data.accessToken);
                            localStorage.saveRefreshToken(data.refreshToken);

                            processingFirst401Refresh = false;

                            sendRequest(originalRequestObject);
                        },
                        error: function() {
                            localStorage.desistLogin();
                            window.location = apiConstants.apiRedirectPath;
                        },
                    });
                }
                else {
                    retryRequest(0, originalRequestObject);
                }
            }
            else {
                originalRequestObject.error(response);
            }
        };
        return ajaxRequestObject;
    }

    function addAuthorizationHeader(ajaxRequestObject) {
        ajaxRequestObject.beforeSend = function(request) {
            request.setRequestHeader("Authorization", `Bearer ${localStorage.getJwtToken()}`);
        };
        return ajaxRequestObject;
    }

    function copyPropertyIfExists(source, target, propertyName, throwErrorIfNull = false) {
        if (
            !isObjectNullOrEmpty(source) &&
            Object.prototype.hasOwnProperty.call(source, propertyName) &&
            source[propertyName] !== null &&
            source[propertyName] !== undefined
        )
            target[propertyName] = source[propertyName];
        else if (throwErrorIfNull) throw new Error(`Property ${propertyName} is required.`);
    }

    function getAjaxRequestObject(ajaxRequestOptions) {
        let ajaxRequestObject = {};

        ajaxRequestObject.crossDomain = true;

        switch (ajaxRequestOptions.type) {
            case requestTypes.GET:
                ajaxRequestObject.type = "GET";
                break;
            case requestTypes.POST:
                ajaxRequestObject.type = "POST";
                break;
            case requestTypes.PUT:
                ajaxRequestObject.type = "PUT";
                break;
            case requestTypes.DELETE:
                ajaxRequestObject.type = "DELETE";
                break;
            default:
                throw new Error("Need to specify http verb for request.");
        }

        copyPropertyIfExists(ajaxRequestOptions, ajaxRequestObject, "url", true);
        copyPropertyIfExists(ajaxRequestOptions, ajaxRequestObject, "contentType");
        copyPropertyIfExists(ajaxRequestOptions, ajaxRequestObject, "processData");
        copyPropertyIfExists(ajaxRequestOptions, ajaxRequestObject, "requestType");
        copyPropertyIfExists(ajaxRequestOptions, ajaxRequestObject, "xhrFields");
        copyPropertyIfExists(ajaxRequestOptions, ajaxRequestObject, "xhr");
        copyPropertyIfExists(ajaxRequestOptions, ajaxRequestObject, "data");
        copyPropertyIfExists(ajaxRequestOptions, ajaxRequestObject, "enctype");
        copyPropertyIfExists(ajaxRequestOptions, ajaxRequestObject, "dataType");
        copyPropertyIfExists(ajaxRequestOptions, ajaxRequestObject, "cache");

        return ajaxRequestObject;
    }

    function configureDefaultSuccessCallback(ajaxRequestObject, ajaxRequestOptions, resolve) {
        // If success callback hasn't been defined on the request options
        // Check if a success message has been defined. If so, notify ui, otherwise continue
        if (typeof ajaxRequestOptions.successCallback !== "function") {
            ajaxRequestObject.success = function(data, status, xhrObject) {
                if (ajaxRequestOptions.successMessage) {
                    notificationFactory.createSuccess(ajaxRequestOptions.successMessage).notify();
                }

                resolve(data, status, xhrObject);
            };
        }
        else {
            // Success callback was defined so set it on the ajax object.
            ajaxRequestObject.success = function(data, status, xhrObject) {
                ajaxRequestOptions.successCallback(data, status, xhrObject, resolve);
            };
        }

        return ajaxRequestObject;
    }

    function configureDefaultErrorCallback(ajaxRequestObject, ajaxRequestOptions, reject) {
        if (typeof ajaxRequestOptions.errorCallback !== "function") {
            ajaxRequestObject.error = function(data) {
                let notifier = ajaxRequestOptions.notifyOnError
                    ? notificationFactory.createErrorsFromXhrObject(data)
                    : notificationFactory.createNone();

                notifier.notify();
                reject(data);
            };
        }
        else {
            ajaxRequestObject.error = function(data) {
                ajaxRequestOptions.errorCallback(data, reject);
            };
        }

        return ajaxRequestObject;
    }

    function sendRequest(ajaxRequestObject) {
        // Define properties for all Requests
        ajaxRequestObject = addAuthorizationHeader(ajaxRequestObject);

        $.ajax(ajaxRequestObject);
    }

    function sendRequestInPromise(ajaxRequestOptions) {
        return new Promise((resolve, reject) => {
            let ajaxRequestObject = getAjaxRequestObject(ajaxRequestOptions);

            ajaxRequestObject = configureDefaultSuccessCallback(ajaxRequestObject, ajaxRequestOptions, resolve);
            ajaxRequestObject = configureDefaultErrorCallback(ajaxRequestObject, ajaxRequestOptions, reject);

            ajaxRequestObject = configure401Retry(ajaxRequestObject);

            sendRequest(ajaxRequestObject);
        });
    }

    function send(ajaxRequestOptions) {
        return sendRequestInPromise(ajaxRequestOptions);
    }

    return { send };
};

export default ajaxRequestWithRetry;