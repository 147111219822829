import PropTypes from "prop-types";
import React from "react";
import GridTable from "../../Components/Grid/GridTable";
import ResultsPager from "../../Components/Grid/ResultsPager";
import EditUserRow from "./EditUserRow";
import UserSearchResultsRow from "./UserSearchResultsRow";

const UserSearchResultsView = ({
                                   editId,
                                   handleSaveUser,
                                   handleResetPassword,
                                   handleSetUserSearchCriteria,
                                   isEditing,
                                   isLoading,
                                   setEditId,
                                   setWarnings,
                                   totalRecords,
                                   users,
                                   userSearchCriteria
                               }) => {
    const handleFirstClick = (event) => {
        event.preventDefault();
        let criteria = {...userSearchCriteria};
        criteria.First();

        handleSetUserSearchCriteria(criteria);
    };

    const handlePreviousClick = (event) => {
        event.preventDefault();
        let criteria = {...userSearchCriteria};
        criteria.Previous();

        handleSetUserSearchCriteria(criteria);
    };

    const handleNextClick = (event) => {
        event.preventDefault();
        let criteria = {...userSearchCriteria};
        criteria.Next();

        handleSetUserSearchCriteria(criteria);
    };

    const handleLastClick = (event) => {
        event.preventDefault();
        let criteria = {...userSearchCriteria};
        criteria.Last(totalRecords);

        handleSetUserSearchCriteria(criteria);
    };

    const handleOrderClick = (event) => {
        event.preventDefault();
        let criteria = {...userSearchCriteria};
        criteria.UpdateSorting(event.target.dataset.id);
        handleSetUserSearchCriteria(criteria);
    };

    if (!users) return null;

    if (users.length === 0) return <p>The user search yielded no results.</p>;

    const pager = (isSecondaryPager) =>
        <ResultsPager
            handleFirstClick={handleFirstClick}
            handlePreviousClick={handlePreviousClick}
            handleNextClick={handleNextClick}
            handleLastClick={handleLastClick}
            isSecondaryPager={isSecondaryPager}
            totalRecords={totalRecords}
            recordsPerPage={userSearchCriteria.recordsPerPage}
            currentPage={userSearchCriteria.page}
            disabled={isLoading}
        />;

    return (
        <>
            {pager()}
            <GridTable>
                <thead>
                <tr>
                    <th scope={"col"} onClick={handleOrderClick} className="is-clickable" data-id="firstName">
                        First Name
                    </th>
                    <th scope={"col"} onClick={handleOrderClick} className="is-clickable" data-id="lastName">
                        Last Name
                    </th>
                    <th scope={"col"} onClick={handleOrderClick} className="is-clickable" data-id="email">
                        Email
                    </th>
                    <th
                        scope={"col"}
                        onClick={handleOrderClick}
                        className="is-clickable"
                        data-id="userRole"
                    >
                        User Role
                    </th>
                    <th scope={"col"} onClick={handleOrderClick} className={"is-clickable"}
                        data-id={"isActive"}>
                        Is Active
                    </th>
                    <th scope={"col"} className={"align-center"}>
                        Actions
                    </th>
                </tr>
                </thead>
                <tbody>
                {users.map((user, index) => {
                    if (!isEditing || editId !== user.userId)
                        return <UserSearchResultsRow key={index}
                                                     disableEdit={isEditing}
                                                     setEditId={setEditId}
                                                     user={user}
                                                     handleResetPassword = {handleResetPassword}
                        />;

                    return <EditUserRow key={index}
                                        handleSaveUser={handleSaveUser}
                                        setEditId={setEditId}
                                        setWarnings={setWarnings}
                                        user={user}
                    />;
                })}
                </tbody>
            </GridTable>

            {pager(true)}
        </>
    );
};

UserSearchResultsView.propTypes = {
    editId: PropTypes.number,
    handleSaveUser: PropTypes.func.isRequired,
    handleResetPassword: PropTypes.func.isRequired,
    handleSetUserSearchCriteria: PropTypes.func.isRequired,
    isEditing: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired,
    setEditId: PropTypes.func.isRequired,
    setWarnings: PropTypes.func.isRequired,
    totalRecords: PropTypes.number.isRequired,
    users: PropTypes.arrayOf(PropTypes.object),
    userSearchCriteria: PropTypes.object.isRequired,
};

export default UserSearchResultsView;
