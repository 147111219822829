import {convertCamelCaseToSpaceDelimited, convertUnderscoreToDash} from "../commonUtilities";
import { isArrayNullOrEmpty } from "./arrayUtilities";
import { objectHasProperty } from "./objectUtilities.js";
import { isArray, isString } from "./typeUtilities";

export function isInSelectList(list, string) {
    return !!list.find((item) => item.value === string || item.text === string);
}

export function createListFromArray(array) {
    //refactor to expression, dry
    let list = [];

    if (isArrayNullOrEmpty(array)) return list;

    array.map((item) => {
        return list.push(createListItem(item, convertCamelCaseToSpaceDelimited(item)));
    });

    return list;
}

export const createListFromArrayOfObjects = (array = [], valueProperty = "", textProperty = "") => {
    let list = [];

    if (isArray(array)) {
        for(let item of array)
        {
            if (objectHasProperty(item, valueProperty) && objectHasProperty(item, textProperty))
                list.push(createListItem(item[valueProperty], item[textProperty]));
        }
    }

    return list;
};

export function createListFromObject(object, usePropertyValueForText = false) {
    let result = [];
    for (let property in object) {
        if (objectHasProperty(object, property)) {
            const listText = usePropertyValueForText ? object[property] : convertPropertyNames(property);
            result.push(createListItem(object[property], listText, property));
        }
    }
    return result;
}

export function createListFromObjectWhereValueIsPropertyName(object) {
    let result = [];
    for (let property in object) {
        if (objectHasProperty(object, property)) {
            result.push(createListItem(property, object[property], property));
        }
    }
    return result;
}

function convertPropertyNames(property) {
	property = convertCamelCaseToSpaceDelimited(property);
	property = convertUnderscoreToDash(property);
	return property;
}

export function createListItem(value, text, id) {
    return {
        text: text ? text : value,
        value: value,
        id: id ? id : value,
    };
}

export const createArchivableListFromArray = (items = [], value) => {
    let list = [];

    if (isString(value) && value.length > 1 && !items.includes(value)) list.push(createListItem(value, `${value} (archived)`));

    if (isArray(items))
        items.map((item) => {
            return list.push(createListItem(item));
        });
    return list;
};

export function getTextByValue(list, value){
	const result = list.find(l => l.value.toString() === value.toString());
	return result ? result.text : "";
}
