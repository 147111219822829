import {
    config,
    mockResolveApiCall,
    requestOptions,
    requestTypes,
    sendRequest,
} from "../Utilities/Api";
import { getLoggedInUser } from "../Utilities/LocalStorage/storageUtilities";

//************************************************** STANDARD API SERVER CALLS ****************************************

class ServerUserApi {
    static getUser() {
        const userId = getLoggedInUser();
        return sendRequest(
            requestOptions(config.URL + `Users/${userId}`,
                requestTypes.GET,
                JSON.stringify(userId),
            ),
        );
    }
    static getUserTokenAsAdmin(userId) {
        return sendRequest(
            requestOptions(config.URL + `Authentication/users/${userId}`,
                requestTypes.GET
            ),
        );
    }

    static getUserTokenFromRefreshToken(token) {
        return sendRequest(
            requestOptions(config.URL + `Authentication/RefreshToken`,
                requestTypes.GET,
                {refreshToken: token}
            ),
        );
    }
}

//************************************************** MOCK API SERVER CALLS ****************************************

class MockUserApi {
    static getUser() {
        return mockResolveApiCall();
    }

    static getUserTokenAsAdmin() {
        return mockResolveApiCall();
    }

    static getUserTokenFromRefreshToken() {
        return mockResolveApiCall();
    }
}

const UserApi = config.environment.MODE === config.modes.SERVER ? ServerUserApi : MockUserApi;
export default UserApi;