import PropTypes from "prop-types";
import React from "react";
import gridConstants from "./gridConstants";

const GridContainerTemplate = ({children, className, hasMarginX, hasMarginY, hasPaddingX, hasPaddingY, isFlush, isList, isReverseOrder}) => {

	let classes = "grid-x";
	classes += className ? ` ${className}` : "";
	classes += hasMarginX ? ` ${gridConstants.class.GRID_MARGIN_X}` : "";
	classes += hasMarginY ? ` ${gridConstants.class.GRID_MARGIN_Y}` : "";
	classes += hasPaddingX ? ` ${gridConstants.class.GRID_PADDING_X}` : "";
	classes += hasPaddingY ? ` ${gridConstants.class.GRID_PADDING_Y}` : "";
	classes += isFlush ? ` ${gridConstants.class.GRID_FLUSH}` : "";
	classes += isReverseOrder ? ` ${gridConstants.class.GRID_REVERSE}` : "";

	if (isList)
		return <ul className={`no-bullet ${classes}`}> {children} </ul>;

	return <div className={classes}> {children} </div>;
};

export const GridContainer = (props) => GridContainerTemplate({...props, isList: false});
export const GridList = (props) => GridContainerTemplate({...props, isList: true});

const props = {
	children: PropTypes.any,
	className: PropTypes.string,
	hasMarginX: PropTypes.bool,
	hasMarginY: PropTypes.bool,
	hasPaddingX: PropTypes.bool,
	hasPaddingY: PropTypes.bool,
	isFlush: PropTypes.bool,
	isReverseOrder: PropTypes.bool,
};

GridContainer.propTypes = props;
GridList.propTypes = props;
GridContainerTemplate.propTypes = {
	...props,
	isList: PropTypes.bool,
};