import { Modal } from "@mui/material";
import { Box } from "@mui/material";
import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import { useEffect } from "react";
import { isNullOrUndefined } from "../../Utilities/commonUtilities";

export const DisplayModal = ({
    children,
    open,
    handleCloseModal,
    ariaTitleById,
    ariaDescribedById,
    width = 500,
}) => {

    var ariaTitleByIdExists = false;

    const checkIfAriaTitleByIdExists = (children) => {
        if (Array.isArray(children))
            for (let child of children)
                checkIfAriaTitleByIdExistsInChild(child);
        else
            checkIfAriaTitleByIdExistsInChild(children);
    }

    const checkIfAriaTitleByIdExistsInChild = (child) => {
        if (!isNullOrUndefined(child.props)) {
            if (!isNullOrUndefined(child.props.id) && child.props.id === ariaTitleById)
                ariaTitleByIdExists = true;
            else if (!isNullOrUndefined(child.props.children))
                checkIfAriaTitleByIdExists(child.props.children);
        }
    }

    useEffect(() => {
        if (!isNullOrUndefined(children) && !isEmpty(children)) {
            checkIfAriaTitleByIdExists(children);
            if (!ariaTitleByIdExists)
                console.error(`In your children, html that surrounds the title of the modal needs to have this id: ${ariaTitleById} for accessibility`);
        }
    }, [ariaTitleById, children]); // eslint-disable-line react-hooks/exhaustive-deps

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width,
        bgcolor: 'background.paper',
        boxShadow: 16,
        p: 4,
    };

    return (
        <Modal
            open={open}
            onClose={handleCloseModal}
            aria-labelledby={ariaTitleById}
            aria-describedby={ariaDescribedById} >
            <Box
                sx={style}
                className={`box_style h2 align-center`} >
                {children}
            </Box>
        </Modal>
    );
};


DisplayModal.propTypes = {
    children: PropTypes.any,
    open: PropTypes.bool.isRequired,
    handleCloseModal: PropTypes.func.isRequired,
    ariaTitleById: PropTypes.string,
    ariaDescribedById: PropTypes.string,
    width: PropTypes.number
};