import PropTypes from "prop-types";
import React from "react";
import {GridCell, gridConstants} from "../../../Components/Grid";
import {
    Button, clearInputFormErrorFields,
    convertInputFormToObject, DisplayField,
    InputForm, inputFormIsValid, SelectField,
    TextField,
    useInputForm,
} from "../../../Components/Inputs";
import {ContentMedium} from "../../../Components/Layout";
import {isTrimmedStringEmpty} from "../../../Utilities/commonUtilities";
import {ACTIVITY_STATUS_NAMES, getActivityStatusList} from "../../activityConstants";
import {REDIRECT_LOCATIONS} from "./activitySettingsConstants";

const ActivitySettingsFormView = ({
                                      activitySettings,
                                      allActivities,
                                      isLoading,
                                      redirectToActivitySearch,
                                      saveActivitySettings,
                                      setWarnings
                                  }) => {
    const activitySettingsForm = useInputForm({...activitySettings});

    const isFormValid = () => {
        clearInputFormErrorFields(activitySettingsForm);

        if (isTrimmedStringEmpty(activitySettingsForm.activityManifestId.value))
            activitySettingsForm.activityManifestId.setError("ID is required.");

        if (isTrimmedStringEmpty(activitySettingsForm.activityTitle.value))
            activitySettingsForm.activityTitle.setError("Title is required.");

        if (isTrimmedStringEmpty(activitySettingsForm.activityStatus.value.toString()))
            activitySettingsForm.activityStatus.setError("Status is required.");

        let isValid = inputFormIsValid(activitySettingsForm);

        if (!isValid)
            setWarnings(["Missing required items.  Please review your responses and try submitting again."]);

        return isValid;
    };

    const handleClickSave = (event, redirectLocation = REDIRECT_LOCATIONS.Search) => {
        event.preventDefault();
        if (isFormValid()) {
            const convertedActivitySettings = convertInputFormToObject(activitySettingsForm);
            saveActivitySettings(convertedActivitySettings, redirectLocation);
        }
    };

    const handleClickCancel = (event) => {
        event.preventDefault();
        redirectToActivitySearch();
    };

    const isNewActivity =  activitySettingsForm.activityId.value === 0;

    return (
        <ContentMedium>
            <InputForm name={"activitySettings"}>
                <TextField
                    {...activitySettingsForm.activityManifestId}
                    label={`ID`}
                />

                <TextField
                    {...activitySettingsForm.activityTitle}
                    label={`Title`}
                />

                {
                    isNewActivity &&
                    <SelectField
                        {...activitySettingsForm.activityIdToDuplicate}
                        label={`Duplicate Configuration and Assets from Existing Activity`}
                        options={allActivities}
                        defaultOption={`Empty`}
                    />
                }
                {
                    !isNewActivity &&
                    <SelectField
                        {...activitySettingsForm.activityStatus}
                        label={`Status`}
                        options={getActivityStatusList()}
                    />
                }

                {
                    isNewActivity &&
                    <DisplayField
                        label={`Status`}
                        name={`activityStatus`}
                        value={ACTIVITY_STATUS_NAMES.Draft}
                    />
                }

                <GridCell medium_columns={gridConstants.column.TWO}>
                    <Button
                        disabled={isLoading}
                        isPrimary
                        label="Save"
                        name="btnSave"
                        onClick={handleClickSave}
                    />
                </GridCell>
                <GridCell medium_columns={gridConstants.column.FOUR}>
                    <Button
                        disabled={isLoading}
                        label="Save and Upload Media Assets"
                        name="btnSaveRedirect"
                        onClick={(event) => handleClickSave(event, REDIRECT_LOCATIONS.Assets)}
                    />
                </GridCell>
                <GridCell medium_columns={gridConstants.column.FOUR}>
                    <Button
                        disabled={isLoading}
                        label="Save and Edit Activity"
                        name="btnSaveRedirect"
                        onClick={(event) => handleClickSave(event, REDIRECT_LOCATIONS.Activity)}
                    />
                </GridCell>
                <GridCell medium_columns={gridConstants.column.TWO}>
                    <Button
                        disabled={isLoading}
                        label="Cancel"
                        name="btnCancel"
                        onClick={handleClickCancel}
                    />
                </GridCell>
            </InputForm>
        </ContentMedium>
    );
};

ActivitySettingsFormView.propTypes = {
    activitySettings: PropTypes.object.isRequired,
    allActivities: PropTypes.array,
    isLoading: PropTypes.bool.isRequired,
    redirectToActivitySearch: PropTypes.func.isRequired,
    saveActivitySettings: PropTypes.func.isRequired,
    setWarnings: PropTypes.func.isRequired
};

export default ActivitySettingsFormView;
