import PropTypes from "prop-types";
import React from "react";
import { GridCell, GridContainer } from "../Grid";
import { HelpText, helpTextSuffix } from "./HelpText";

export const InputForm = ({ children, helpHtml, name }) => {
    const handleSubmit = (event) => event.preventDefault();

    return (
        <form aria-describedby={`${name}${helpTextSuffix}`} onSubmit={handleSubmit}>
            <GridContainer hasMarginX>
                {children}
                <GridCell>
                    <HelpText name={name} helpText={helpHtml}/>
                </GridCell>
            </GridContainer>
        </form>

    );
};

InputForm.propTypes = {
    children: PropTypes.any.isRequired,
    helpHtml: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    name: PropTypes.string.isRequired,
};