import React from "react";
import {HashRouter} from "react-router-dom";
import {SiteRoutes} from "./Routes";

export const Router = () => {

	return (
        <HashRouter>
			<SiteRoutes/>
		</HashRouter>
	);
};