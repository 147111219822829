import PropTypes from "prop-types";
import React from "react";
import {GridContainer, GridFlexSpacer} from "../Grid";
import Header from "./Header";
import {Main} from "./Main";
import Footer from "./Footer";

export const TemplateAdmin = ({children}) => {
    return (
        <>
            <noscript>You need to enable JavaScript to run this app.</noscript>
            <Header/>
            <Main id={`main__admin`}>
                <GridContainer hasMarginX hasMarginY>
                    <GridFlexSpacer>
                        {children}
                    </GridFlexSpacer>
                </GridContainer>
            </Main>
            <Footer />
        </>
    );
};

TemplateAdmin.propTypes = {
    children: PropTypes.any
};