// {"Id":"PA11","Name":"Syllable Animal Feed","Url":"https://fcim-web.fcim.org/fcrr-dsca/PA11/PA11.json","TaskIds":["PA11-Splash","PA11-Game","PA11-Results"]},
export const createActivityManifest = ({
                                           Id,
                                           Name,
                                           TaskIds,
                                           Url
                                       } = {}) =>
    ({
        id: Id || "",
        name: Name || "",
        taskIds: TaskIds || [],
        url: Url || ""
    });

export const createActivityDetails = ({
                                          Description,
                                          Grade,
                                          Id,
                                          Name,
                                          Subtopic,
                                          Tasks,
                                          TaskIds,
                                          ThemingData,
                                          ThumbnailUrl,
                                          Title,
                                          Topic
                                      } = {}) =>
    ({
        description: Description || "",
        grade: Grade || "",
        id: Id || "",
        subtopic: Subtopic || "",
        tasks: Tasks || [],
        taskIds: TaskIds || [],
        themingData: ThemingData,
        thumbnailUrl: ThumbnailUrl || "https://via.placeholder.com/400x300",
        title: Title || Name || "",
        topic: Topic || ""
    });

// https://fcim-web.fcim.org/fcrr-dsca/PA11/PA11.json
// {"Id":"PA11","Title":"Syllable Animal Feed","Description":"Segment syllables into words","Grade":"K-1",
// "Topic":"Phonological Awareness","Subtopic":"Syllables","TaskIds":["PA11-Splash","PA11-Game","PA11-Results"],
// "ThumbnailUrl" : "https://via.placeholder.com/400x300",
// "Tasks":[],"ThemingData":{"BackgroundImage":"https://fcim-web.fcim.org/fcrr-dsca/PA11/Assets/bg.jpg",
// "BackgroundAudio":"https://fcim-web.fcim.org/fcrr-dsca/PA11/Assets/Music.mp3",
// "InteractionEffects":[{"Interaction":"activity complete","Image":"","Audio":"","Text":"","Duration":0.0,"Data":"restart"}],"Data":""}}