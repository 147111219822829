export const otherText = "Other";
export const otherTextSeparator = " - ";
export const arrayDelimiter = "|";

export const inputType = {
    EMAIL: "email",
    CHECKBOX: "checkbox",
    NUMBER: "number",
    PASSWORD: "password",
    RADIO: "radio",
    TEXT: "text",
};

export const inputStyles = {
    button: {
        types: {
            FIRST: " button--first",
            PREVIOUS: " button--previous",
            NEXT: " button--icon button--next",
            LAST: " button--last",
            CLEAR: " button--clear",
        },
        theme: " button",
        isCollapsed: "",
        isExpanded: " expanded",
        isLarge: " large",
        isPrimary: "",
        isRibbon: " button--ribbon",
        isSecondary: " hollow",
        isTertiary: " button--tertiary",
        notCentered: " notCentered"
    },
};

export const DateTimePickerType = {
    Calendar: 0,
    CalendarWithTime: 1,
    TimeOnly: 2
};
