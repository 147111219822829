import PropTypes from "prop-types";
import React from "react";
import { GridCell, gridConstants } from "../../Components/Grid";
import {
    Button, clearInputFormErrorFields,
    convertInputFormToObject,
    EmailField,
    InputForm, inputFormIsValid,
    PasswordField,
    useInputForm,
} from "../../Components/Inputs";
import { PageTitle } from "../../Components/Landmarks";
import { ContentSmall } from "../../Components/Layout";
import Link from "../../Components/Link";
import { isTrimmedStringEmpty } from "../../Utilities/commonUtilities";
import { locations } from "../../Utilities/Location";
import {signInModel} from "./signInFactory";

const SignInView = ({ handleSignIn, isLoading, setWarnings }) => {
    const signInForm = useInputForm(signInModel());

    const isFormValid = () => {
        clearInputFormErrorFields(signInForm);

        if (isTrimmedStringEmpty(signInForm.email.value)) {
            signInForm.email.setError("Email is required.");
        }

        if (isTrimmedStringEmpty(signInForm.password.value)) {
            signInForm.password.setError("Password is required.");
        }

        let isValid = inputFormIsValid(signInForm);

        if (!isValid)
            setWarnings(["Missing required items.  Please review your responses and try submitting again."]);

        return isValid;
    };

    const handleClickSubmit = () => {
        if (isFormValid()) {
            const signIn = convertInputFormToObject(signInForm);
            handleSignIn(signIn);
        }
    };

    return (
        <ContentSmall>
            <PageTitle h1={locations.SIGN_IN.label} hasBorder/>
            <InputForm name={"signIn"}>
                <EmailField {...signInForm.email} />

                <PasswordField {...signInForm.password} />
                <GridCell>
                    <Button
                        isPrimary
                        name="btnSubmit"
                        label="Submit"
                        onClick={handleClickSubmit}
                        disabled={isLoading}
                    />
                </GridCell>

                <GridCell small_columns={gridConstants.column.SIX}>
                    <p>
                        <Link {...locations.FORGOT_PASSWORD.link} />
                    </p>
                </GridCell>
                <GridCell small_columns={gridConstants.column.SIX}>
                    <p className={`align-right`}>
                        <Link {...locations.REGISTER.link} />
                    </p>
                </GridCell>
            </InputForm>
        </ContentSmall>
    );
};

SignInView.propTypes = {
    handleSignIn: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    setWarnings: PropTypes.func.isRequired
};

export default SignInView;
