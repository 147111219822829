import PropTypes from "prop-types";
import { Button } from "../../Components/Inputs";
import { ContentSmall } from "../../Components/Layout";
import { locations } from "../../Utilities/Location";
import { Link } from "../../Components/Link";
import { DisplayModal } from "../../Components/Modal";
import {isArrayNullOrEmpty} from "../../Utilities/Types/arrayUtilities";

const LockedActivityModal = ({
    assetName = "",
    lockedActivities = [],
    handleCloseModal,
}) => {

    const openModal = !isArrayNullOrEmpty(lockedActivities);
    const ariaTitleById = `parent-modal-title`;
    const ariaDescribedById = `parent-modal-description`;

    return (
        <DisplayModal
            open={openModal}
            handleCloseModal={handleCloseModal}
            ariaTitleById={ariaTitleById}
            ariaDescribedById={ariaDescribedById}>
            <p id={ariaTitleById}>
                {`Activities that use asset - ${assetName}`}
            </p>
            <ContentSmall id={ariaDescribedById}>
                <ul> {
                    lockedActivities.map((activity) => {
                        return <li key={activity.activityId}>
                            <Link
                                to={locations.EDIT_ACTIVITY.getPath(activity.activityId)}
                                label={`${activity.activityTitle} (${activity.activityManifestId}) `}
                            />
                        </li>
                    })
                }
                </ul>
                <Button
                    disabled={false}
                    label="Close"
                    name="btnClose"
                    onClick={handleCloseModal}
                    isPrimary
                />
            </ContentSmall>
        </DisplayModal>
    );
};

LockedActivityModal.propTypes = {
    assetName: PropTypes.string,
    lockedActivities: PropTypes.array,
    handleCloseModal: PropTypes.func.isRequired
};

export default LockedActivityModal;
