import { useState } from "react";
import {
    convertCamelCaseToSpaceDelimited,
    isNullOrUndefined,
    isTrimmedStringEmpty
} from "../../Utilities/commonUtilities";
import { optionIndexInArray } from "../../Utilities/Types/arrayUtilities";
import { createListFromObject } from "../../Utilities/Types/listUtilities";
import {isObjectNullOrEmpty, objectHasProperty} from "../../Utilities/Types/objectUtilities";

import { inputStyles, otherTextSeparator } from "./inputConstants";

export function useInput(initialValue) {
    const [value, setValue] = useState(initialValue);

    function handleChange(e) {
        const value = e && e.target ? e.target.value : e;
        setValue(value);
    }

    return {
        onChange: handleChange,
        setValue,
        value,
    };
}
export function useInputFormNoStateForTesting(initialValue, callbackAfterChange) {
    let items = initialValue;
    const setItems = (value) => items = value;
    return useInputFormAbstract(initialValue, callbackAfterChange, items, setItems);
}
export function useInputForm(initialValue, callbackAfterChange) {
    const [items, setItems] = useState(initialValue);
    return useInputFormAbstract(initialValue, callbackAfterChange, items, setItems);
}
export function useInputFormAbstract(initialValue, callbackAfterChange, items, setItems) {

    function handleChange(e, id) {
        const name = id ? id : e.target.name;
        const item = this ? this : items[name];
        item.setValue(e.target.value);

        if (callbackAfterChange) callbackAfterChange();
    }

    function setValue(value) {
        const result = { ...items };
        if(value !== result[this.name].value) {
            result[this.name].value = value;
            setItems(result);
        }
    }

    function setError(error) {
        const result = {...items };
        if(error !== result[this.name].error) {
            result[this.name].error = error;
            setItems(result);
        }
    }

    for (let property in items) {
        if (!objectHasProperty(items, property)) break;
        const item = items[property];
        if (isNullOrUndefined(item) || typeof item !== "object" || Array.isArray(item))
            items[property] = {
                value: item,
                onChange: handleChange,
                setValue,
                setError,
                name: property,
                error: "",
                label: convertCamelCaseToSpaceDelimited(property, true),
            };
    }

    return {
        ...items,
    };
}

export const clearInputFormErrorFields = (object) => {
    for (let property in object) {
        if (objectHasProperty(object, property)) {
            object[property].setError("");
        }
    }
};

export const reinitializeInputFormFields = (object, initialValues) => {
    for (let property in object) {
        if (objectHasProperty(object, property)) {
            const newValue = objectHasProperty(initialValues, property) ? initialValues[property] : "";
            if (!isObjectNullOrEmpty(object[property])){
                object[property].setValue(newValue);
                object[property].setError("");
            }
        }
    }
};


export const inputFormIsValid = (object) => {
    let isValid = true;

    for (let property in object) {
        if (objectHasProperty(object, property)) {
            if( !isTrimmedStringEmpty(object[property].error) && isValid) {
                isValid = false;
                focusOnFormElement(property);
            }
        }
    }

    return isValid;
};

export function focusOnFormElement(elementId) {
    let element = document.getElementById(elementId);

    if(!element) {
        element = document.getElementById(`${elementId}_0`);
    }

    if(!element) {
        element = document.getElementsByClassName(elementId);
        if(!element || element.length === 0)
            element = null;
        else
            element = element[0];
    }

    if(!element)
        return;

    element.focus();
    if(element.scrollIntoView)
        element.scrollIntoView(false);
}

export function convertInputFormToObject(form) {
    let object = clone(form);
    if (!object) return null;
    for (let property in object) {
        if (objectHasProperty(object, property)) {
            const item = object[property];

            if (objectHasProperty(item, "onChange"))
                object[property] = item.value;
        }
    }
    return object;
}

export function createFakeEvent(value, id, object = {}, targetObject = {}) {
    return {
        target: { ...{ value, id, name: id }, ...targetObject },
        ...object,
        ...{
            preventDefault: () => {
            },
        },
    };
}

function clone(obj) {
    if (null == obj || "object" != typeof obj) return null;
    const copy = obj.constructor();
    for (const attr in obj) {
        if (objectHasProperty(obj, attr)) copy[attr] = obj[attr];
    }
    return copy;
}

export function createYesNoObject(isBoolean = true) {
    return {
        Yes: isBoolean ? "true" : "Yes",
        No: isBoolean ? "false" : "No",
    };
}

export function createYesNoList(isBoolean = true) {
    const options = createYesNoObject(isBoolean);
    return createListFromObject(options);
}

export function isValueInArray(array, value, wholeWord = true) {
    return optionIndexInArray(array, value, wholeWord) > -1;
}

export function removeValueInArray(array, value, wholeWord = true) {
    const index = optionIndexInArray(array, value, wholeWord);
    array.splice(index, 1);
}

export function updateCheckboxListArray(
    optionChanged,
    currentOptions,
    allOptions,
) {
    const isChecked =
        optionIndexInArray(currentOptions, optionChanged, false) > -1;
    let optionsSelected = [];

    if (isChecked) {
        optionsSelected = currentOptions.reduce((agg, option) => {
            if (!option.startsWith(optionChanged)) agg.push(option);

            return agg;
        }, []);
    }
    else {
        for (let i = 0; i < allOptions.length; i++) {
            if (allOptions[i] === optionChanged) optionsSelected.push(optionChanged);
            else {
                let index = optionIndexInArray(currentOptions, allOptions[i], false);
                if (index > -1) optionsSelected.push(currentOptions[index]);
            }
        }
    }

    return optionsSelected;
}

export function updateCheckboxOtherValue(
    arrayOfSelected,
    selectedValue,
    otherValue,
) {
    const index = optionIndexInArray(arrayOfSelected, selectedValue, false);
    arrayOfSelected[index] = generateOtherValue(
        selectedValue,
        otherValue,
    );

    if (isValueInArray(arrayOfSelected, selectedValue))
        removeValueInArray(arrayOfSelected, selectedValue);
}

export function generateOtherValue(
    selectedValue,
    otherValue,
    separator = otherTextSeparator,
) {
    return `${selectedValue}${separator}${otherValue}`;
}

export function appendedOtherValue(value, inputText, separator = otherTextSeparator) {
    const otherText = value.split(inputText + separator);
    if (otherText.length === 2) return otherText[1];
    return "";
}

export function appendedCheckboxOtherValue(
    arrayOfSelected,
    checkboxText,
    separator = otherTextSeparator,
) {
    const otherIndex = optionIndexInArray(
        arrayOfSelected,
        checkboxText + separator,
        false,
    );
    if (otherIndex > -1) {
        const otherText = arrayOfSelected[otherIndex].split(checkboxText + separator);
        if (otherText.length === 2) return otherText[1];
    }

    return "";
}

export function appendedCheckboxOtherValueExists(
    checkboxText,
    arrayOfSelected,
    separator = otherTextSeparator,
) {
    const otherIndex = optionIndexInArray(arrayOfSelected, checkboxText, false);
    if (otherIndex > -1) {
        const otherText = arrayOfSelected[otherIndex].split(separator);
        if (
            otherText.length === 1 ||
            (otherText.length === 2 && otherText[1].trim() === "")
        )
            return false;
    }

    return true;
}

export function getButtonClasses({ className="", isTertiary, isExpanded, isPrimary, isRibbon, buttonType, notCentered }) {
    className += inputStyles.button.theme;
    className += isRibbon ? inputStyles.button.isRibbon : "";
    className += isPrimary ? inputStyles.button.isPrimary :
        isTertiary ? inputStyles.button.isTertiary : inputStyles.button.isSecondary;
    className += isExpanded ? inputStyles.button.isExpanded : inputStyles.button.isCollapsed;
    className += buttonType || "";
    className += notCentered ? inputStyles.button.notCentered : "";

    return className;
}

export function isValidEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}