import {createYesNoObject} from "../../Components/Inputs/inputUtility";
import {isNullOrUndefined} from "../../Utilities/commonUtilities";
import {searchModel} from "../../Utilities/Search";

export const userSearchModel = (userId, userInfo, roleId, isActive) => {
    return Object.assign({}, searchModel(userId.toString(), "lastName", false, "lastName"), {
        userInfo: userInfo || "",
        roleId: roleId || "-1",
        isActive: !isNullOrUndefined(isActive) ? isActive : createYesNoObject(false).Yes,
        ClearUserSearch: clearUserSearch,
    });
};

function clearUserSearch() {
    this.userInfo = "";
    this.roleId = "-1";
    this.isActive = "";
    this.ClearSearch(false);
}